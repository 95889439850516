import { useState, useEffect } from "react";
import { Card } from "react-bootstrap";

// This component is used to display a notice/success/error alert on the UI
const CustomAlert = ({ type, title, message }) => {
    const [visible, setVisible] = useState(true);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        // Fade in
        setOpacity(1);

        // Fade out after 4.5 seconds, then remove the alert after 5 seconds
        const fadeOutTimer = setTimeout(() => setOpacity(0), 4500);
        const removeTimer = setTimeout(() => setVisible(false), 5000);

        return () => {
            clearTimeout(fadeOutTimer);
            clearTimeout(removeTimer);
        };
    }, []);

    if (!visible) return null;

    const getAlertStyles = () => {
        switch (type) {
            case "success":
                return { background: "#d4edda", borderColor: "#155724", color: "#155724" };
            case "error":
                return { background: "#f8d7da", borderColor: "#721c24", color: "#721c24" };
            case "notice":
            default:
                return { background: "#e2e3e5", borderColor: "#6c757d", color: "#6c757d" };
        }
    };

    return (
        <Card
            style={{
                position: "fixed",
                bottom: "20px",
                right: "20px", // Moved to the right-bottom corner
                width: "300px",
                padding: "10px",
                border: `1px solid ${getAlertStyles().borderColor}`,
                borderRadius: "8px",
                boxShadow: "0 4px 6px rgba(0,0,0,0.1)",
                background: getAlertStyles().background,
                color: getAlertStyles().color,
                opacity: opacity,
                transition: "opacity 0.5s ease-in-out",
                direction: "rtl", // Set right-to-left text direction
                textAlign: "right", // Align text to the right
            }}
        >
            <Card.Header
                style={{
                    fontWeight: "bold",
                    background: "transparent",
                    borderBottom: "none",
                    textAlign: "right",
                }}
            >
                {title}
            </Card.Header>
            <Card.Body>{message}</Card.Body>
        </Card>
    );
};

export default CustomAlert;
