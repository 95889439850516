import React from "react";
import { useState } from "react";
import "../ManagerDashboard.css";
import HelpBubble from "../Robuts Components/HelpBubble";
import DataTable from "../Robuts Components/DataTable";
import { Button, Modal, Badge } from "react-bootstrap";
import { convertToDate } from "../../../backend/timeFunctions";

function StudentsPane({ students, groups, setCurrentTab }) {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showStudentDetailsModal, setShowStudentDetailsModal] = useState(false);

  const onStudentRowClick = (student) => {
    setSelectedStudent(student);
    setShowStudentDetailsModal(true);
  };

  // This function checks how many students are waiting for acceptence. It is used to render a number badge.
  const calcNumberOfNewStudents = () => {
    return (
      students?.filter((student) => student?.status !== "active")?.length || 0
    );
  };

  return (
    <div>
      <div className="helpHeader">
        <h2>חניכים</h2>
        <HelpBubble
          text={`כאן תוכל/י לצפות ולערוך את כלל החניכים בארגון!\n `}
        />
      </div>
      <Button
        variant="info"
        onClick={() => {
          setCurrentTab("new_students");
        }}
        style={{ position: "relative", paddingLeft: "25px" }}
      >
        {calcNumberOfNewStudents() > 0 && (
          <div
            style={{
              position: "absolute",
              top: "-10px",
              left: "-10px",
              backgroundColor: "red",
              color: "white",
              fontSize: "16px",
              fontWeight: "bold",
              borderRadius: "50%",
              width: "30px",
              height: "30px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              boxShadow: "0 0 5px rgba(0, 0, 0, 0.3)",
            }}
          >
            {calcNumberOfNewStudents()}
          </div>
        )}
        <div>
          <strong>חניכים חדשים</strong>
        </div>
      </Button>
      <p style={{ marginTop: "2vh" }}>לחץ על חניך להצגת פירוט</p>

      {/* Display the list of students */}
      <div
        className="groupsContainer"
        style={{
          maxHeight: "60vh",
          overflowY: "auto",
          paddingRight: "10px",
        }}
      >
        <DataTable
          data={students || []}
          type="students"
          columnMapping={{
            address: "כתובת",
            age: "גיל",
            gender: "מין",
            name: "שם החניך",
            groupsIDs: "קבוצת החניך",
          }}
          ignoredAttributes={[
            "id",
            "beltColor",
            "birthday",
            "classPresenceRecords",
            "firstParentEmailAddress",
            "firstParentName",
            "firstParentNumber",
            "imageURI",
            "imageUploadTime",
            "specialFields",
            "status",
            "weight",
            "weightKG",
            "secondParentEmailAddress",
            "secondParentName",
            "secondParentNumber",
            "momPhoneNumber",
            "momEmailAddress",
            "momName",
            "IDnumber",
            "studentID",
            "studentCreatedTime",
            "studentSource",
            "createdAt",
            "shortsSize",
            "pantsSize",
          ]}
          onRowClick={onStudentRowClick}
          metaData={groups || []}
        />
      </div>

      {/** Student Details Modal */}
      {selectedStudent && (
        <Modal
          show={showStudentDetailsModal}
          onHide={() => setShowStudentDetailsModal(false)}
        >
          <Modal.Header className="eventFormHeader">
            <Modal.Title className="ml-auto">
              {selectedStudent?.name || "שם לא זמין"}
            </Modal.Title>
            <Button
              variant="light"
              className="close-button"
              onClick={() => setShowStudentDetailsModal(false)}
            >
              &times;
            </Button>
          </Modal.Header>
          <Modal.Body
            className="hebrewText"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
            }}
          >
            <div>
              <p>
                <strong>שם החניך :</strong> {selectedStudent?.name || "-"}
              </p>
              <p>
                <strong>מין :</strong> {selectedStudent?.gender || "-"}
              </p>
              <p>
                <strong>גיל :</strong> {selectedStudent?.age || "-"}
              </p>
              <p>
                <strong>כתובת :</strong> {selectedStudent?.address || "-"}
              </p>
              <p>
                <strong>תעודת זהות :</strong> {selectedStudent?.IDnumber || "-"}
              </p>
              <p>
                <strong>יום הולדת :</strong>{" "}
                {selectedStudent?.birthday?.seconds
                  ? convertToDate(selectedStudent.birthday.seconds, 0)
                  : "-"}
              </p>
              <p>
                <strong>צבע חגורה :</strong>{" "}
                {selectedStudent?.specialFields?.beltColor || "-"}
              </p>
              <p>
                <strong>משקל החניך בק"ג :</strong>{" "}
                {selectedStudent?.specialFields?.weightKG || "-"}
              </p>
              <p>
                <strong>שם הורה 1 :</strong>{" "}
                {selectedStudent?.firstParentName || "-"}
              </p>
              <p>
                <strong>כתובת אימייל הורה 1 :</strong>{" "}
                {selectedStudent?.firstParentEmailAddress || "-"}
              </p>
              <p>
                <strong>מספר טלפון הורה 1 :</strong>{" "}
                {selectedStudent?.firstParentNumber || "-"}
              </p>
              <p>
                <strong>שם הורה 2 :</strong>{" "}
                {selectedStudent?.secondParentName || "-"}
              </p>
              <p>
                <strong>כתובת אימייל הורה 2 :</strong>{" "}
                {selectedStudent?.secondParentEmailAddress || "-"}
              </p>
              <p>
                <strong>מספר טלפון הורה 2 :</strong>{" "}
                {selectedStudent?.secondParentNumber || "-"}
              </p>
            </div>
            <div>
              {selectedStudent?.imageURI && (
                <img
                  style={{ width: "20vh", borderRadius: "5vh" }}
                  src={selectedStudent.imageURI}
                  alt="תמונת החניך"
                />
              )}
              <p>
                <strong>קבוצות שהחניך מתאמן בהן:</strong>
              </p>
              {Array.isArray(selectedStudent?.groupsIDs) &&
              selectedStudent.groupsIDs.length > 0
                ? selectedStudent.groupsIDs.map((groupId, index) => {
                    const group = groups?.find((g) => g.id === groupId);
                    return <div key={index}>{group?.name || groupId}</div>;
                  })
                : "-"}
            </div>
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
}

export default StudentsPane;
