export function getDaysInMonth(month) {
  // Check if the month is valid (between 1 and 12)
  if (month < 1 || month > 12) {
    return "Invalid month";
  }

  // Create a date object for the given month
  let date = new Date(2022, month, 0);

  // Return the number of days in the month
  return date.getDate();
}

export function getHourString(timestamp) {
  // Get date from timestamp
  const date = new Date(timestamp * 1000);

  // Extract the hour and minute from the date object
  const hour = date.getHours();
  const minute = date.getMinutes();

  // Use the padStart method to add leading zeroes if necessary
  const hourString = hour.toString().padStart(2, "0");
  const minuteString = minute.toString().padStart(2, "0");

  // Return the formatted hour string
  return `${hourString}:${minuteString}`;
}

// This function convert Firebase Timestamp object (which comprised of seconds and nanoseconds) into date string format
export function convertToDate(seconds, nanoseconds) {
  // Convert seconds to milliseconds
  let milliseconds = seconds * 1000 + nanoseconds / 1e6;

  // Create a Date object
  let date = new Date(milliseconds);

  // Extract day, month, and year
  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  let year = String(date.getFullYear()).slice(-2);

  // Format as dd/mm/yy
  return `${day}/${month}/${year}`;
}

// This function convert Firebase Timestamp object (which comprised of seconds and nanoseconds) into hour string format
export function convertToTime(seconds, nanoseconds) {
  // Convert seconds to milliseconds
  let milliseconds = seconds * 1000 + nanoseconds / 1e6;

  // Create a Date object
  let date = new Date(milliseconds);

  // Extract hours and minutes
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');

  // Format as hh:mm
  return `${hours}:${minutes}`;
}

// This function convert Firebase Timestamp object (which comprised of seconds and nanoseconds) into hour and date string format
export function convertToDateTime(seconds, nanoseconds) {
  // Convert seconds to milliseconds
  let milliseconds = seconds * 1000 + nanoseconds / 1e6;

  // Create a Date object
  let date = new Date(milliseconds);

  // Extract hours, minutes, day, month, and year
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  let month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  let year = String(date.getFullYear()).slice(-2);

  // Format as hh:mm dd/mm/yy
  return `${hours}:${minutes} ${day}/${month}/${year}`;
}

// This function converts react bootstrap date and hour string input into Firebase Timestamp object (which comprised of seconds and nanoseconds)
export function convertToFirestoreTimestamp(dateTimeString) {
  console.log(dateTimeString);
  // Parse the input date string (format: "YYYY-MM-DDTHH:mm")
  let date = new Date(dateTimeString);
  // Convert to Firestore timestamp format
  return {
    seconds: Math.floor(date.getTime() / 1000),
    nanoseconds: 0
  };
}
// This function converts Firebase Timestamp object (which comprised of seconds and nanoseconds) into react bootstrap date and hour string input
export function convertFromFirestoreTimestamp(timestamp) {
  if (!timestamp || typeof timestamp.seconds !== 'number') {
    throw new Error("Invalid Firestore timestamp");
  }

  let date = new Date(timestamp.seconds * 1000);

  // Format date as "YYYY-MM-DDTHH:mm"
  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, '0');
  let day = String(date.getDate()).padStart(2, '0');
  let hours = String(date.getHours()).padStart(2, '0');
  let minutes = String(date.getMinutes()).padStart(2, '0');

  return `${year}-${month}-${day}T${hours}:${minutes}`;
}