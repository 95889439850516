import { React, useState } from "react";
import "../ManagerDashboard.css";
import HelpBubble from "../Robuts Components/HelpBubble";
import EventCard from "../EventCard";
import {
  convertFromFirestoreTimestamp,
  convertToFirestoreTimestamp,
} from "../../../backend/timeFunctions";
import {
  deleteEvent,
  updateExistingEvent,
  writeNewEvent,
} from "../../../backend/firebaseFunctions";
import { Button, Modal, Form } from "react-bootstrap";
import { Timestamp } from "firebase/firestore";

const EventsPane = ({
  events,
  onEventUpdate,
  onEventAdd,
  onEventDelete,
  userID,
  activateAlert,
}) => {
  const [showEventFormModal, setShowEventFormModal] = useState(false);
  const [eventName, setEventName] = useState("");
  const [eventStartTime, setEventStartTime] = useState("");
  const [eventEndTime, setEventEndTime] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [eventLocation, setEventLocation] = useState("");
  const [eventID, setEventID] = useState("");
  const [isEditedEvent, setIsEditedEvent] = useState(false);

  const openEventFormModal = () => {
    setShowEventFormModal(true);
  };

  const closeEventModal = () => {
    setShowEventFormModal(false);
    setEventName("");
    setEventStartTime("");
    setEventEndTime("");
    setEventDescription("");
    setIsEditedEvent(false);
  };

  const handleEventNameChange = (event) => {
    setEventName(event.target.value);
  };

  const handleEventStartTimeChange = (event) => {
    setEventStartTime(event.target.value);
  };

  const handleEventEndTimeChange = (event) => {
    setEventEndTime(event.target.value);
  };

  const handleEventDescriptionChange = (event) => {
    setEventDescription(event.target.value);
  };

  const handleEventLocationChange = (event) => {
    setEventLocation(event.target.value);
  };

  const handleNewEventSubmit = async () => {
    // Create the new event object, create appropriate time stamps
    let startDateTimestamp = convertToFirestoreTimestamp(eventStartTime);
    let endTimeTimestamp = convertToFirestoreTimestamp(eventEndTime);

    const newEvent = {
      date:
        eventStartTime && startDateTimestamp
          ? new Timestamp(
              startDateTimestamp?.seconds,
              startDateTimestamp?.nanoseconds
            )
          : null,
      details: eventDescription,
      endDate:
        eventEndTime && endTimeTimestamp
          ? new Timestamp(
              endTimeTimestamp?.seconds,
              endTimeTimestamp?.nanoseconds
            )
          : null,
      groupsInEvent: [], // TODO Finish Implementation
      isInvitationSent: null,
      location: eventLocation,
      name: eventName,
      paymentUrl: "", // TODO Finish Implementation
    };

    try {
      // First perform the server operation
      const updatedEvent = await writeNewEvent(userID, newEvent);
      // Only update local state after successful server operation
      onEventAdd(updatedEvent);
      activateAlert("success", "אליפות!", "האירוע נוצר בהצלחה");
      closeEventModal();
    } catch (error) {
      console.error("Failed to create event:", error);
      activateAlert("error", "אופס", "התרחשה תקלה ביצירת האירוע");
    }
  };

  const handleEditedEventSubmit = async () => {
    // Create the new event object, create appropriate time stamps
    let startDateTimestamp = convertToFirestoreTimestamp(eventStartTime);
    let endTimeTimestamp = convertToFirestoreTimestamp(eventEndTime);

    const updatedEvent = {
      date: eventStartTime
        ? new Timestamp(
            startDateTimestamp?.seconds,
            startDateTimestamp?.nanoseconds
          )
        : null,
      details: eventDescription,
      id: eventID,
      endDate: eventEndTime
        ? new Timestamp(
            endTimeTimestamp?.seconds,
            endTimeTimestamp?.nanoseconds
          )
        : null,
      groupsInEvent: [], // TODO Finish Implementation
      isInvitationSent: null,
      location: eventLocation,
      name: eventName,
      paymentUrl: "", // TODO Finish Implementation
    };

    try {
      // First perform the server operation
      await updateExistingEvent(userID, updatedEvent);
      console.log("Event Updated successfully.");

      // Only update local state after successful server operation
      onEventUpdate(updatedEvent);
      activateAlert("success", "אליפות!", "האירוע התעדכן בהצלחה");
      closeEventModal();
    } catch (error) {
      console.error("Failed to edit event:", error);
      activateAlert("error", "אופס", "לא הצלחנו לעדכן את האירוע");
    }
  };

  const openEventEditModal = (event) => {
    setEventName(event.name);
    setEventID(event.id);
    setEventLocation(event.location);
    setEventStartTime(convertFromFirestoreTimestamp(event.date));
    setEventEndTime(convertFromFirestoreTimestamp(event.endDate));
    setEventDescription(event.details);
    setIsEditedEvent(true);
    setShowEventFormModal(true);
  };

  const handleEventDelete = async (event) => {
    try {
      // First perform the server operation
      await deleteEvent(userID, event.id);
      console.log("Event deleted successfully.");

      // Only update local state after successful server operation
      onEventDelete(event.id);
      activateAlert("success", "אליפות!", "האירוע נמחק בהצלחה");
    } catch (error) {
      console.error("Failed to delete event:", error);
      activateAlert("error", "אופס", "לא הצלחנו למחוק את האירוע");
    }
  };

  return (
    <div>
      <div className="eventsHeaderContainer">
        <div className="helpHeader">
          <h2>אירועים</h2>
          <Button
            variant="success"
            className="marginRight"
            onClick={openEventFormModal}
          >
            צור אירוע חדש
          </Button>
        </div>

        <HelpBubble
          text={`במסך האירועים ניתן לצפות בכלל האירועים, לערוך אותם וכמובן למחוק אותם! \n `}
        />
      </div>
      <p style={{ marginTop: "2vh" }}>לחץ על הפלוס להצגת פירוט</p>

      {events?.map((event, index) => (
        <EventCard
          key={event.id || index}
          event={event}
          onEdit={openEventEditModal}
          onDelete={handleEventDelete}
        />
      ))}
      {/** Event Form Modal */}
      <Modal show={showEventFormModal} onHide={closeEventModal}>
        <Modal.Header className="eventFormHeader">
          <Modal.Title className="ml-auto">הוסף אירוע חדש</Modal.Title>
          <Button
            variant="light"
            className="close-button"
            onClick={closeEventModal}
          >
            &times;
          </Button>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group
              className="eventFormFieldContainer"
              controlId="eventName"
            >
              <Form.Label>שם האירוע</Form.Label>
              <Form.Control
                type="text"
                className="eventFormInput"
                value={eventName}
                onChange={handleEventNameChange}
              />
            </Form.Group>
            <Form.Group
              className="eventFormFieldContainer"
              controlId="eventLocation"
            >
              <Form.Label>מיקום האירוע</Form.Label>
              <Form.Control
                type="text"
                className="eventFormInput"
                value={eventLocation}
                onChange={handleEventLocationChange}
              />
            </Form.Group>
            <Form.Group
              className="eventFormFieldContainer"
              controlId="eventStartTime"
            >
              <Form.Label>תאריך וזמן התחלת האירוע</Form.Label>
              <Form.Control
                type="datetime-local"
                className="eventFormInput"
                value={eventStartTime}
                onChange={handleEventStartTimeChange}
              />
            </Form.Group>
            <Form.Group
              className="eventFormFieldContainer"
              controlId="eventTime"
            >
              <Form.Label>תאריך וזמן סיום האירוע</Form.Label>
              <Form.Control
                type="datetime-local"
                className="eventFormInput"
                value={eventEndTime}
                onChange={handleEventEndTimeChange}
              />
            </Form.Group>

            <Form.Group
              className="eventFormFieldContainer"
              controlId="eventDescription"
            >
              <Form.Label>תיאור האירוע</Form.Label>
              <Form.Control
                as="textarea"
                rows={3}
                className="eventFormInput"
                value={eventDescription}
                onChange={handleEventDescriptionChange}
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={closeEventModal}>
            ביטול
          </Button>
          <Button
            variant="primary"
            onClick={
              !isEditedEvent ? handleNewEventSubmit : handleEditedEventSubmit
            }
          >
            שמירה
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default EventsPane;
