import React from "react";
import "./HomePane.css";
import {
  BarChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Legend,
  Bar,
} from "recharts";
import { PieChart, Pie, Cell } from "recharts";
import CountUp from "react-countup";
const COLORS = ["#FF8042", "rgb(15, 123, 13)"];

function HomePane({ userData, activateAlert, students, groups, presenceLogs }) {
  const DashboardCard = ({ title, description, flex, children }) => {
    return (
      <div
        className="dashboard-card"
        style={{ flex: `${flex}`, direction: "rtl" }}
      >
        <p className="secular-one-regular subtitle" style={{ margin: 0 }}>
          {title}
        </p>
        <p
          className="normal-text"
          style={{ marginTop: 0, marginBottom: "2vh" }}
        >
          {description}
        </p>
        {children}
      </div>
    );
  };

  // This function returns an array of objects, each representing a group and the number of students within it
  const getGroupsStudentsData = () => {
    return groups.map((group) => {
      const studentCount = students.filter((student) =>
        student?.groupsIDs?.includes(group.id)
      ).length;
      return { name: group.name, חניכים: studentCount };
    });
  };

  // Returns number of groups
  const getNumberOfGroups = () => {
    return groups.length;
  };

  // Return number of students (which are associated with an active group)
  const getNumberOfStudent = () => {
    const activeGroupsIDs = groups.map((group) => group.id);
    return students.filter(
      (student) =>
        student?.groupsIDs?.length > 0 &&
        activeGroupsIDs.includes(student.groupsIDs[0])
    ).length;
  };

  // Return the number of training sessions that occured in the previous month, using the presence logs
  const getNumberOfTrainingInLastMonth = () => {
    // Get the current date and the date one month ago
    const now = new Date();
    const oneMonthAgo = new Date();
    oneMonthAgo.setMonth(now.getMonth() - 1); // Subtract 1 month from the current date

    // Filter the presence logs to only include those within the last month
    const logsInLastMonth = presenceLogs.filter((log) => {
      const logTime = log.logTime?.toDate(); // Convert Firebase timestamp to JavaScript Date
      return logTime >= oneMonthAgo;
    });

    // Return the count of logs in the last month
    return logsInLastMonth.length;
  };

  // Return number of male students
  const getNumberOfMaleStudents = () => {
    return students.filter((student) => student?.gender === "זכר").length;
  };

  // Return number of female students
  const getNumberOfFemleStudents = () => {
    return students.filter((student) => student?.gender === "נקבה").length;
  };

  // Return number of students that their age is between the given range (bottom to top)
  const getNumberOfStudentsBetweenAgeRange = (bottom, top) => {
    // Get the current date
    const now = new Date();

    // Filter students whose age falls within the bottom and top range
    const filteredStudents = students.filter((student) => {
      const birthday = student?.birthday?.toDate(); // Assuming birthday is a Firebase Timestamp, convert it to Date
      const age = now.getFullYear() - birthday?.getFullYear(); // Calculate age based on the year difference
      const monthDiff = now.getMonth() - birthday?.getMonth();
      const isBeforeBirthdayThisYear =
        monthDiff < 0 ||
        (monthDiff === 0 && now.getDate() < birthday?.getDate());

      // Adjust age if the birthday hasn't occurred yet this year
      const finalAge = isBeforeBirthdayThisYear ? age - 1 : age;

      // Check if age is between bottom and top
      return finalAge >= bottom && finalAge <= top;
    });

    // Return the count of students within the age range
    return filteredStudents.length;
  };

  // Return number of students that were supposed to attend class but didn't - AKA Class Misses
  const getNumberOfTimesStudentsMissedClassLastMonth = () => {
    let total = 0;
    presenceLogs.map((log) => {
      total += log.totalmissingStudents;
      return log;
    });
    return total;
  };
  // Return number of students that were supposed to attend class and actually attended - AKA Class Attendeces
  const getNumberOfTimesStudentsAttendedClassLastMonth = () => {
    let total = 0;
    presenceLogs.map((log) => {
      total += log.totalPresentStudents;
      return log;
    });
    return total;
  };

  // Due to the fact the dashboard is actually not dynamid, there is no need to use states in this file, I use simple variables.
  const groupsStudentsData = getGroupsStudentsData();
  const numberOfGroups = getNumberOfGroups();
  const numberOfStudents = getNumberOfStudent();
  const numberOfTrainingInLastMonth = getNumberOfTrainingInLastMonth();
  const numberOfMaleStudents = getNumberOfMaleStudents();
  const numberOfFemaleStudents = getNumberOfFemleStudents();
  const numberOfTimesStudentsMissedClassLastMonth =
    getNumberOfTimesStudentsMissedClassLastMonth();
  const numberOfTimesStudentAttendedClassLastMonth =
    getNumberOfTimesStudentsAttendedClassLastMonth();

  return (
    <div className="dashboard-wrapper">
      <p className="secular-one-regular title">ערב טוב, {userData?.userName}</p>
      <div className="pane-row-wrapper">
        <DashboardCard
          title={"חניכים בקבוצות"}
          description={"כמה חניכים יש בכל קבוצה"}
          flex={2}
        >
          <BarChart width={650} height={250} data={groupsStudentsData}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis tickMargin={20} />
            <Tooltip />
            <Legend verticalAlign="bottom" height={36} />
            <Bar dataKey="חניכים" fill="rgb(15, 123, 13)" />
          </BarChart>
        </DashboardCard>
        <DashboardCard
          title={"נתוני על"}
          description={"המספרים החשובים של הארגון"}
          flex={1}
        >
          <p className="secular-one-regular semi-subtitle">
            <strong className="important-number">
              <CountUp start={0} end={numberOfStudents} duration={3} />
              <t> </t>
            </strong>
            חניכים
          </p>
          <p className="secular-one-regular semi-subtitle">
            <strong className="important-number">
              <CountUp start={0} end={numberOfGroups} duration={3} />
              <t> </t>
            </strong>
            קבוצות
          </p>
          <p className="secular-one-regular semi-subtitle">
            <strong className="important-number">
              <CountUp
                start={0}
                end={numberOfStudents / numberOfGroups}
                duration={3}
              />
              <t> </t>
            </strong>
            חניכים בקבוצה בממוצע
          </p>
          <p className="secular-one-regular semi-subtitle">
            <strong className="important-number">
              <CountUp
                start={0}
                end={numberOfTrainingInLastMonth}
                duration={3}
              />
              <t> </t>
            </strong>
            אימונים בחודש האחרון
          </p>
        </DashboardCard>
      </div>
      <div className="pane-row-wrapper">
        <DashboardCard
          title={"נוכחות באימונים"}
          description={"כמה פעמים חניכים נעדרו מאימונים בחודש האחרון?"}
          flex={1}
        >
          <div style={{ justifyContent: "center", alignItems: "center" }}>
            <PieChart width={300} height={200}>
              <Pie
                data={[
                  {
                    name: "חיסורים",
                    value: numberOfTimesStudentsMissedClassLastMonth,
                  },
                  {
                    name: "נוכחויות",
                    value: numberOfTimesStudentAttendedClassLastMonth,
                  },
                ]}
                cx={140}
                cy={85}
                innerRadius={0}
                outerRadius={80}
                fill="#8884d8"
                paddingAngle={10}
                dataKey="value"
                labelLine={false}
                label={({
                  cx,
                  cy,
                  midAngle,
                  innerRadius,
                  outerRadius,
                  percent,
                  name,
                  value,
                }) => {
                  const RADIAN = Math.PI / 180;
                  const radius =
                    innerRadius + (outerRadius - innerRadius) * 0.5; // Middle of the slice
                  const x = cx + radius * Math.cos(-midAngle * RADIAN);
                  const y = cy + radius * Math.sin(-midAngle * RADIAN);

                  return (
                    <text
                      x={x}
                      y={y}
                      fill="white"
                      textAnchor="middle"
                      dominantBaseline="central"
                      fontSize={14}
                      fontWeight="bold"
                    >
                      {value}
                    </text>
                  );
                }}
              >
                {[
                  { name: "חיסורים", value: 42 },
                  { name: "נוכחיות", value: 123 },
                ].map((entry, index) => (
                  <Cell
                    key={`cell-${index}`}
                    fill={COLORS[index % COLORS.length]}
                  />
                ))}
              </Pie>
              <Legend
                iconSize={18} // Controls the size of the color box
                layout="horizontal"
                verticalAlign="bottom"
                align="center"
              />
              <Tooltip />
            </PieChart>
          </div>
        </DashboardCard>
        <DashboardCard title={"פילוחי נתונים"} description={""} flex={1}>
          <div style={{ display: "flex", flexDirection: "row" }}>
            <BarChart
              width={200}
              height={200}
              data={[
                {
                  name: "מגדר",
                  נשים: numberOfFemaleStudents,
                  גברים: numberOfMaleStudents,
                },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickMargin={30} />
              <Tooltip />
              <Legend />
              <Bar dataKey="נשים" fill="#B6A6E9" />
              <Bar dataKey="גברים" fill="#F8AE54" />
            </BarChart>
            <BarChart
              width={300}
              height={200}
              data={[
                {
                  name: "גיל",
                  "6-10": getNumberOfStudentsBetweenAgeRange(6, 10),
                  "10-14": getNumberOfStudentsBetweenAgeRange(10, 14),
                  "14-18": getNumberOfStudentsBetweenAgeRange(14, 18),
                  "18+": getNumberOfStudentsBetweenAgeRange(18, 99),
                },
              ]}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="name" />
              <YAxis tickMargin={30} />
              <Tooltip />
              <Bar dataKey="6-10" fill="#92C5F9" />
              <Bar dataKey="10-14" fill="#4394E5" />
              <Bar dataKey="14-18" fill="#0066CC" />
              <Bar dataKey="18+" fill="#004D99" />
              <Legend />
            </BarChart>
          </div>
        </DashboardCard>
      </div>
    </div>
  );
}

export default HomePane;
