import React, { useState, useCallback } from "react";
import Table from "react-bootstrap/Table";
import Form from "react-bootstrap/Form";
import { debounce } from "lodash";
import { FaFilter, FaTimes } from "react-icons/fa";

const DataTable = ({
  data, // The actual data
  ignoredAttributes = [], // An array of data attributes we don't want to render
  type = "default", // the type parameter is used for specific actions for specific reasons.
  columnMapping = {}, // This param is used to change the name of columns
  onRowClick, // This function occurs when the user clicks on a row in the table
  metaData = [], // This is more data for specific usage.
  messageIfEmpty = "אין מידע", // This is the message to be displayed if the table has no data
}) => {
  // State management for filtering and loading
  const [filters, setFilters] = useState({}); // Stores the current filter values
  const [activeFilters, setActiveFilters] = useState({}); // Tracks which filters are currently active
  const [isLoading, setIsLoading] = useState(false); // Loading state for filter operations

  // Define columns based on data and mapping
  let columns = Object.keys(columnMapping).concat(
    [...new Set(data.flatMap(Object.keys))].filter(
      (col) => !ignoredAttributes.includes(col) && !columnMapping[col]
    )
  );

  // Debounced filter handler - prevents excessive re-renders during typing
  const debouncedFilter = useCallback(
    debounce((column, value) => {
      setFilters((prev) => ({ ...prev, [column]: value.toLowerCase() }));
      setActiveFilters((prev) => ({ ...prev, [column]: value !== "" }));
    }, 300),
    []
  );

  // Handle filter input changes
  const handleFilterChange = (column, value) => {
    setIsLoading(true);
    debouncedFilter(column, value);
    setIsLoading(false);
  };

  // Clear all active filters
  const clearFilters = () => {
    setFilters({});
    setActiveFilters({});
  };

  // Determine the appropriate input type for each column
  const getFilterType = (column, value) => {
    if (typeof value === "number") return "number";
    if (value instanceof Date) return "date";
    return "text";
  };

  // Early return if no data is available
  if (!Array.isArray(data) || data.length === 0) {
    return <p className="text-muted">אין נתונים זמינים</p>;
  }

  // This function fetches the group data by the group id, it is used to translate groups ID numbers to group names
  const getGroupByID = (groupID) => {
    return metaData?.find((group) => group.id === groupID[0]) || null;
  };

  const formatCellValue = (value, col = "") => {
    // If no value, we render accordingly
    if (value === undefined || value === null) return "-";
    // Specific Scenarion Handle - If it's a data table of groups, and there are multiple training days, we render it visually
    if (typeof value === "object" && type === "groups") {
      return value
        .map((slot) => `יום ${slot.day}' בשעות ${slot.range}`)
        .join("\n");
    }
    return String(value);
  };

  // These lines take the data, and tamper it according to specific use cases
  const transformedData = data
    .filter((row) => {
      if (type === "students" && row.status === "toReview") return false;
      if (type === "newstudents" && row.status !== "toReview") return false;
      return true;
    })
    .map((row) => {
      // For groups type, preserve all properties including ID
      if (type === "groups") {
        return { ...row };
      }

      if ((type === "students" || type === "newstudents") && row.groupsIDs) {
        return {
          ...row,
          groupsIDs: getGroupByID(row.groupsIDs)
            ? `${getGroupByID(row.groupsIDs)?.name}, סניף ${
                getGroupByID(row.groupsIDs)?.dojo
              }`
            : "-",
        };
      }
      return row;
    });

  // If the table is empty, render a message instead of table
  if (transformedData.length === 0) {
    return <h4 style={{ color: "gray" }}>{messageIfEmpty}</h4>;
  }

  // These lines filter the data according to the user's input
  const filteredData = transformedData.filter((row) =>
    columns.every(
      (col) =>
        !filters[col] ||
        (row[col] && String(row[col]).toLowerCase().includes(filters[col]))
    )
  );

  return (
    <div className="data-table-container">
      {/* Filter controls section */}
      <div className="d-flex justify-content-between align-items-center mb-3">
        <div className="d-flex align-items-center gap-2">
          {/* Show clear filters button only when filters are active */}
          {Object.values(activeFilters).some(Boolean) && (
            <button
              className="btn btn-outline-secondary btn-sm"
              onClick={clearFilters}
            >
              <FaTimes className="me-1" />
              נקה סינון
            </button>
          )}
        </div>
      </div>

      <Table
        striped
        bordered
        hover
        responsive
        className={`text-center table-${type}`}
        style={{ backgroundColor: "#f8f9fa", flex: 1 }}
      >
        {/* Table header with column names and filter indicators */}
        <thead style={{ backgroundColor: "lightgrey", color: "black" }}>
          <tr>
            {columns.map((col) => (
              <th key={col} className="position-relative">
                <div className="d-flex align-items-center justify-content-center">
                  <span className="position-relative">
                    {columnMapping[col] || col}
                    {/* Show filter icon when filter is active */}
                    {activeFilters[col] && (
                      <FaFilter
                        className="text-primary position-absolute"
                        size={12}
                        style={{
                          right: "-20px",
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                      />
                    )}
                  </span>
                </div>
              </th>
            ))}
          </tr>
          {/* Filter input row */}
          <tr>
            {columns.map((col) => (
              <th key={col}>
                <Form.Control
                  type={getFilterType(col, data[0]?.[col])}
                  placeholder="סנן"
                  onChange={(e) => handleFilterChange(col, e.target.value)}
                  dir="rtl"
                  style={{
                    fontSize: "small",
                    borderColor: activeFilters[col] ? "#0d6efd" : undefined,
                    textAlign: "right",
                    direction: "rtl",
                    unicodeBidi: "embed",
                  }}
                />
              </th>
            ))}
          </tr>
        </thead>
        {/* Table body with loading, empty, and data states */}
        <tbody>
          {isLoading ? (
            // Loading state
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                <div className="spinner-border text-primary" role="status">
                  <span className="visually-hidden">טוען...</span>
                </div>
              </td>
            </tr>
          ) : filteredData.length === 0 ? (
            // No results state
            <tr>
              <td colSpan={columns.length} className="text-center py-4">
                <div className="text-muted">
                  <p className="mb-0">לא נמצאו תוצאות מתאימות</p>
                </div>
              </td>
            </tr>
          ) : (
            // Data rows
            filteredData.map((row, rowIndex) => (
              <tr
                key={rowIndex}
                onClick={() => onRowClick?.(row)}
                style={{ cursor: onRowClick ? "pointer" : "default" }}
              >
                {columns.map((col) => (
                  <td
                    key={col}
                    style={{
                      backgroundColor: rowIndex % 2 ? "#e9ecef" : "white",
                      textAlign: "center",
                    }}
                  >
                    {formatCellValue(row[col], col)}
                  </td>
                ))}
              </tr>
            ))
          )}
        </tbody>
      </Table>
    </div>
  );
};

export default DataTable;
