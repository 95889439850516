import React from "react";
import { Modal, Button } from "react-bootstrap";
import "../ManagerDashboard.css";
import { useEffect } from "react";

const UpdatesModal = ({ showUpdatesModal, setShowUpdatesModal }) => {
  const UPDATE_ID = "2024/03/28"; // Change this when a new update is released
  const LOCAL_STORAGE_KEY = "lastSeenUpdate";

  useEffect(() => {
    const lastSeenUpdate = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (lastSeenUpdate !== UPDATE_ID) {
      setShowUpdatesModal(true); // Show modal if user hasn't seen this update
    }
  }, []);

  const handleClose = () => {
    localStorage.setItem(LOCAL_STORAGE_KEY, UPDATE_ID);
    setShowUpdatesModal(false);
  };

  return (
    <Modal
      show={showUpdatesModal}
      onHide={setShowUpdatesModal}
      centered
      size="lg"
      style={{ overflow: "hidden" }}
    >
      <Modal.Header className="eventFormHeader">
        <Modal.Title
          className="ml-auto"
          style={{ fontSize: "1.8rem", fontWeight: "bold" }}
        >
          🚀 עדכונים - {UPDATE_ID}
        </Modal.Title>
        <Button variant="light" className="close-button" onClick={handleClose}>
          &times;
        </Button>
      </Modal.Header>
      <Modal.Body
        style={{
          maxHeight: "65%",
          overflowY: "auto",
          padding: "20px",
          direction: "rtl",
        }}
      >
        <h4>מסך הבית החדש!</h4>
        <p>
          מסך הבית החדש מכיל דשבורד מתקדם המציג נתונים וסטטיסטיקה ארגונית
          באמצעות גרפים ואיורים
        </p>
        <h4>הפקת דו"ח נוכחות</h4>
        <p>
          כעת, ניתן להפיק דו"חות נוכחות לכל קבוצה בארגון מה-30 הימים האחרונים,
          בצורה מסודרת ונוחה, בפירוט של כלל האימונים.
        </p>
        <h4>הפקת דו"ח קבוצות</h4>
        <p>
          כעת, ניתן להפיק דוח קבוצות המפרט את כלל הקבוצות בארגון והפרטים שלהן,
          וגם סטטיסטיקה ארגונית של כמות חניכים וקבוצות כוללת!
        </p>
        <h4>הפקת דו"ח חניכים</h4>
        <p>
          כעת, ניתן להפיק דוח חניכים המפרט את כלל החניכים בארגון והפרטים שלהם,
          כולל חישוב סטטיסטי של רמת הנוכחות של כל חניך ב-30 הימים האחרונים!
        </p>
        <p>ניתן למצוא את הפקת הדוחות במסך "דוחות מידע"</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          סגור
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default UpdatesModal;
