import { initializeApp } from "firebase/app";
import {
  getFirestore,
  doc,
  setDoc,
  getDoc,
  collection,
  deleteDoc,
  getDocs,
  addDoc,
  writeBatch,
  updateDoc,
  query,
} from "firebase/firestore";
import { getStorage, ref, getDownloadURL, uploadBytes } from "firebase/storage";
import {
  getAuth,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  signOut,
  signInWithCustomToken,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyAqeO06E_OXSWKMBNmBnIlD7g60fY1jYSY",
  authDomain: "jks-israel-app.firebaseapp.com",
  databaseURL:
    "https://jks-israel-app-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "jks-israel-app",
  storageBucket: "jks-israel-app.appspot.com",
  messagingSenderId: "265218182067",
  appId: "1:265218182067:web:762cc3b2e5c0a98ab519c6",
};

const app = initializeApp(firebaseConfig);

// Initialize Cloud Firestore and get a reference to the service
const db = getFirestore(app);

// Init Auth
export const auth = getAuth(app);

// Images Storage Connection
const storage = getStorage(app);

export const getGroupData = async (userType, ID, groupID) => {
  const groupData = await getDoc(
    doc(
      db,
      Number(userType) !== 3 ? "users" : "organizations",
      ID,
      "groups",
      groupID
    )
  );
  return groupData.data();
};

export const submitNewStudentToGroup = async (
  userType,
  ID,
  groupID,
  studentData,
  image
) => {
  let newStudentRef = doc(
    collection(
      db,
      Number(userType) !== 3 ? "users" : "organizations",
      ID,
      "students"
    )
  );

  const newId = newStudentRef.id;

  // Fetching the image from the device's cache
  const imageRef = await fetch(image);

  // Image Time Stamp
  const imageUploadTime = Date.now();

  // Creating a blob to send to the storage
  const blob = await imageRef.blob();

  // Setting a filename according to the student ID
  const filename = `${newId}${imageUploadTime}.jpg`;

  // Uploading the blob to the storage under the filename
  await uploadBytes(ref(storage, filename), blob);

  // getting the uploaded image URI from the storage
  const imageURI = await getStudentImageURI(newId, imageUploadTime);

  // sending the student's data to the DB
  return await setDoc(newStudentRef, {
    ...studentData,
    studentSource: "web",
    studentCreatedTime: new Date(),
    id: newId,
    imageUploadTime: imageUploadTime,
    imageURI: imageURI,
    groupsIDs: [groupID],
    classPresenceRecords: [],
    status: "toReview",
  })
    .then((res) => {
      console.log(`student was submitted`);
      return true;
    })
    .catch((err) => {
      console.log(`student wasn't submited`);
      return false;
    });
};

// A function the fetches the imageURI of a student's image that is stored on the cloud
export const getStudentImageURI = async (studentID, timeUpload) => {
  return await getDownloadURL(ref(storage, `${studentID}${timeUpload}.jpg`))
    .then((imageURI) => {
      return imageURI;
    })
    .catch(() => {
      return "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_1280.png";
    });
};

//// Managers
// Function to sign in the manager
export const managerSignIn = async (email, password) => {
  try {
    const { user } = await signInWithEmailAndPassword(auth, email, password);
    console.log("Manager signed in successfully");
    return user;
    // Additional logic after successful sign-in
  } catch (error) {
    console.log("Error signing in:", error.message);
    // Handle sign-in error
    throw error;
  }
};

// Function to sign up the manager
export const managerSignUp = async (
  email,
  password,
  firstName,
  lastName,
  unionName,
  phoneNumber
) => {
  try {
    // Create a new user with email and password
    const { user } = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    );
    console.log("Manager signed up successfully");

    // Remove password from the data to be stored in Firestore
    const userData = {
      firstName,
      lastName,
      unionName,
      phoneNumber,
      activationStatus: "not active",
    };

    // Create a new document in Firestore under "users/{unionName}"
    const unionDocRef = doc(db, "users", user.uid);
    await setDoc(unionDocRef, userData);

    console.log("Data saved to Firestore");
    return user;
    // Additional logic after successful sign-up and data storage
  } catch (error) {
    console.log("Error signing up:", error.message);
    // Handle sign-up error
  }
};

export const managerLogout = async () => {
  const res = await signOut(auth);
  console.log(res);
};

export const getUserData = async (uid) => {
  try {
    const docRef = doc(db, "users", uid);
    const unionDoc = await getDoc(docRef);
    if (unionDoc.exists) {
      return unionDoc.data();
    } else {
      console.log("User data not found");
      return {
        status: "user does not exist",
      };
    }
  } catch (error) {
    console.error("Error fetching user data:", error);
  }
};

// Syncing Data
export const syncUserEvents = async (uid, events) => {
  try {
    const eventsCollectionRef = collection(db, "users", uid, "events");
    const eventsQuerySnapshot = await getDocs(eventsCollectionRef);

    const batch = writeBatch(db);
    eventsQuerySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    const eventPromises = events.map(async (event) => {
      const eventDocRef = doc(collection(db, "users", uid, "events"));
      await setDoc(eventDocRef, { ...event, id: eventDocRef.id });
    });
    await Promise.all(eventPromises);
  } catch (error) {
    console.log(error);
  }
};

export const getUserEvents = async (uid) => {
  try {
    const eventsCollectionRef = collection(db, "users", uid, "events");
    const userEventsQuerySnapshot = await getDocs(eventsCollectionRef);

    const userEvents = userEventsQuerySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));

    return userEvents;
  } catch (error) {
    console.error("Error getting user events:", error);
    return null;
  }
};

export const syncUserCoaches = async (uid, coaches) => {
  try {
    const coachesCollectionRef = collection(db, "users", uid, "coaches");
    const coachesQuerySnapshot = await getDocs(coachesCollectionRef);

    const batch = writeBatch(db);
    coachesQuerySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    const coachPromises = coaches.map(async (coach) => {
      const coachDocRef = doc(collection(db, "users", uid, "coaches"));
      await setDoc(coachDocRef, { ...coach, id: coachDocRef.id });
    });
    await Promise.all(coachPromises);
  } catch (error) {
    console.log(error);
  }
};

export const getUserCoaches = async (uid) => {
  try {
    const coachesCollectionRef = collection(db, "users", uid, "coaches");
    const userCoachesQuerySnapshot = await getDocs(coachesCollectionRef);

    const userCoaches = userCoachesQuerySnapshot.docs.map((doc) => ({
      id: doc.id, // Ensure the document ID is included
      ...doc.data(),
    }));

    return userCoaches;
  } catch (error) {
    console.error("Error getting user coaches:", error);
    return null;
  }
};

export const syncUserGroups = async (uid, groups) => {
  try {
    const groupsCollectionRef = collection(db, "users", uid, "groups");
    const groupsQuerySnapshot = await getDocs(groupsCollectionRef);

    const batch = writeBatch(db);
    groupsQuerySnapshot.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();

    const groupPromises = groups.map(async (group) => {
      const groupDocRef = doc(collection(db, "users", uid, "groups"));
      await setDoc(groupDocRef, { ...group, id: groupDocRef.id });
    });
    await Promise.all(groupPromises);
  } catch (error) {
    console.log(error);
  }
};

export const getUserGroups = async (uid) => {
  try {
    const groupsCollectionRef = collection(db, "users", uid, "groups");
    const userGroupsQuerySnapshot = await getDocs(groupsCollectionRef);

    const userGroups = userGroupsQuerySnapshot.docs.map((doc) => ({
      id: doc.id, // Ensure the document ID is included
      ...doc.data(),
    }));

    return userGroups;
  } catch (error) {
    console.error("Error getting user groups:", error);
    return null;
  }
};

export const getUserStudents = async (uid) => {
  try {
    const studentsCollectionRef = collection(db, "users", uid, "students");
    const userStudentsQuery = query(studentsCollectionRef);
    const userStudentsQuerySnapshot = await getDocs(userStudentsQuery);

    const userStudents = [];
    userStudentsQuerySnapshot.forEach((doc) => {
      const student = doc.data();
      userStudents.push(student);
    });
    return userStudents;
  } catch (error) {
    console.error("Error getting user students:", error);
    return null;
  }
};

// WRITE Functions
// These functions are performing specific WRITE actions on specific object types
export const deleteEvent = async (uid, eventID) => {
  const eventsCollectionRef = collection(db, "users", uid, "events");
  try {
    const eventDocRef = doc(eventsCollectionRef, eventID);
    await deleteDoc(eventDocRef);
    console.log(`Event ${eventID} deleted successfully.`);
    return eventID; // Return the deleted event ID
  } catch (error) {
    console.error("Error deleting event: ", error);
    throw error;
  }
};

export const writeNewEvent = async (uid, event) => {
  if (!uid || !event) {
    throw new Error("Missing required parameters: uid or event object");
  }

  try {
    const eventsCollectionRef = collection(db, "users", uid, "events");
    const docRef = await addDoc(eventsCollectionRef, event);

    await updateDoc(docRef, { id: docRef.id });

    const updatedEvent = { ...event, id: docRef.id };
    return updatedEvent;
  } catch (error) {
    console.error("Error adding event: ", error);
    throw error;
  }
};

// This function updates existing event
export const updateExistingEvent = async (uid, event) => {
  if (!uid || !event || !event.id) {
    throw new Error("Missing required parameters: uid or event.id");
  }

  try {
    const eventDocRef = doc(db, "users", uid, "events", event.id);
    await updateDoc(eventDocRef, event);
    return event; // Return the updated event object
  } catch (error) {
    console.error("Error updating event: ", error);
    throw error;
  }
};

// This function deletes group
export const deleteGroup = async (uid, groupID) => {
  const groupsCollectionRef = collection(db, "users", uid, "groups");
  try {
    const groupDocRef = doc(groupsCollectionRef, groupID);
    await deleteDoc(groupDocRef);
    console.log(`Group ${groupID} deleted successfully.`);
    return groupID; // Return the deleted group ID
  } catch (error) {
    console.error("Error deleting group: ", error);
    throw error;
  }
};

export const writeNewGroup = async (uid, group) => {
  if (!uid || !group) {
    throw new Error("Missing required parameters: uid or group object");
  }

  try {
    const groupsCollectionRef = collection(db, "users", uid, "groups");

    const docRef = await addDoc(groupsCollectionRef, group);
    await updateDoc(docRef, { id: docRef.id });

    const updatedGroup = { ...group, id: docRef.id };
    return updatedGroup;
  } catch (error) {
    console.error("Error adding group: ", error);
    throw error;
  }
};

export const updateExistingGroup = async (uid, group) => {
  if (!uid || !group || !group.id) {
    throw new Error("Missing required parameters: uid or group.id");
  }

  try {
    const groupDocRef = doc(db, "users", uid, "groups", group.id);
    await updateDoc(groupDocRef, group);
    return group; // Return the updated group object
  } catch (error) {
    console.error("Error updating group: ", error);
    throw error;
  }
};

export const signInWithCode = async (authCode) => {
  try {
    console.log("performing https call");
    const response = await fetch(
      "https://us-central1-jks-israel-app.cloudfunctions.net/verifycodeandcreateuser",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ code: authCode }), // Send the code in the request body
      }
    );
    const data = await response.json();
    console.log(`data: ${JSON.stringify(data)}`);

    if (response.ok) {
      console.log("User created successfully", data);

      // Get the custom token from the response
      const customToken = data.token;

      // Sign in with the custom token
      const current_user_credential = await signInWithCustomToken(
        auth,
        customToken
      );

      console.log(
        `User signed in with custom token! ${JSON.stringify(
          current_user_credential.user
        )}`
      );

      return data; // You can return the token or UID here
    } else {
      console.error("Error:", data.error);
      return null;
    }
  } catch (error) {
    console.error("Request failed:", error);
  }
};

// This function changes a student's status to "active" and thus accepting it
export const acceptNewStudent = async (uid, studentID) => {
  // Check if something is missing
  if (!uid || !studentID) {
    throw new Error("Missing required parameters: uid or studentID");
  }

  try {
    // Find the student's doc
    const studentRef = doc(db, "users", uid, "students", studentID);

    // Update the student's status to "active"
    await updateDoc(studentRef, { status: "active" });

    return true; // Report the success
  } catch (error) {
    console.error("Error accepting student:", error);
    throw error;
  }
};

// This function deletes a student
export const deleteStudent = async (uid, studentID) => {
  // Find the students collection
  const studentsCollectionRef = collection(db, "users", uid, "students");
  try {
    // Find the target student doc
    const studentDocRef = doc(studentsCollectionRef, studentID);
    // Perform the delete action
    await deleteDoc(studentDocRef);
    // log the status
    console.log(`Student ${studentID} deleted successfully.`);
    return true;
  } catch (error) {
    // Handle Error
    console.error("Error deleting student: ", error);
  }
  return false;
};

// This function is used to retrieve all the presence logs of an array of groups
export const getPresenceLogs = async (uid, groupsIDs) => {
  try {
    const allPresenceLogs = [];

    // Iterate over each groupID in the provided groupsIDs array
    for (const groupID of groupsIDs) {
      // Reference to the specific group document
      const groupRef = doc(db, "users", uid, "groups", groupID);

      // Reference to the "presenceLogs" sub-collection within the group
      const presenceLogsRef = collection(groupRef, "presence_logs");

      // Get the snapshot of the presence logs in the current group
      const presenceLogsSnapshot = await getDocs(presenceLogsRef);

      // Add the logs of this group to the combined array
      presenceLogsSnapshot.forEach((logDoc) => {
        allPresenceLogs.push(logDoc.data());
      });
    }

    // Return the combined array of presence logs from all groups
    return allPresenceLogs;
  } catch (error) {
    console.error("Error fetching presence logs: ", error);
    throw new Error("Failed to fetch presence logs");
  }
};
