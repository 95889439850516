// Custom
import "./general.css";

// React Router
import { useParams } from "react-router-dom";

// Bootstrap Components
import ProgressBar from "react-bootstrap/ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import Button from "react-bootstrap/Button";

// Firebase
import { getGroupData, signInWithCode } from "../backend/firebaseFunctions";
import { useEffect, useState } from "react";
import { auth } from "../backend/firebaseFunctions";

// Time
import { WelcomePhase } from "./Phases Components/WelcomePhase";
import StudentDetailsPhase from "./Phases Components/StudentDetailsPhase";
import ParentsDetailsPhase from "./Phases Components/ParentsDetailsPhase";
import DetailsApprovePhase from "./Phases Components/DetailsApprovePhase";
import { Form } from "react-bootstrap";

// Checks email validity
export function isValidEmail(email) {
  // Use a regular expression to check if the email address follows the proper format
  const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,6}$/;
  return emailRegex.test(email);
}

export function getAge(birthday) {
  // Get the current date
  const currentDate = new Date();

  // Calculate the person's age
  let age = currentDate.getFullYear() - birthday.getFullYear();
  if (
    currentDate.getMonth() < birthday.getMonth() ||
    (currentDate.getMonth() === birthday.getMonth() &&
      currentDate.getDate() < birthday.getDate())
  ) {
    age--;
  }

  // Return the age
  return Math.ceil(age);
}

export const getDaysString = (groupData) => {
  let str = "";
  if (groupData?.trainingDays) {
    str += "ימי ";
    groupData.trainingDays.map((day, index) => {
      str += day;
      if (index !== groupData.trainingDays.length - 1) {
        str += ", ";
      }
      return day;
    });
  } else if (groupData?.trainingSlots) {
    str = "";
    groupData.trainingSlots.map((slotObject, index) => {
      str += `יום `;
      str += slotObject.day;
      str += `'`;
      str += ` - `;
      str += slotObject.range;
      if (index !== groupData.trainingSlots.length - 1) {
        str += `&`;
      }
    });
  }
  return str;
};

const NewStudentForm = () => {
  const [verificationStatus, setVerificationStatus] = useState(
    "awaiting auth code submit"
  );
  const [error, setError] = useState(null);
  const [showSpinner, setShowSpinner] = useState(false);

  const [authCode, setAuthCode] = useState("");

  const initCodeAuth = async () => {
    setShowSpinner(true);

    try {
      let isCodeValid = await signInWithCode(authCode);
      console.log(`isCodeValid: ${isCodeValid}`);
      if (isCodeValid) {
        console.log(`code ${authCode} valid`);
        console.log(auth);
        setVerificationStatus("success");
      } else {
        console.log(`code ${authCode} is not valid`);
        console.log(auth);
        setError("קוד מאמן שגוי, אנא נסה שנית");
        setVerificationStatus("awaiting auth code submit");
      }
    } catch (err) {
      console.log(`error verifying code: ${err}`);
      setVerificationStatus("awaiting auth code submit");
    } finally {
      setShowSpinner(false);
    }
  };

  // getting the user or organization ID and the GroupID from the URL
  const { ID, groupID, coachType, userType } = useParams();

  // Setting states for the data fetching and page loading
  const [groupData, setGroupData] = useState();
  const [loaded, setLoaded] = useState(false);

  // Progress Bar state
  const [progress, setProgress] = useState(0);

  const [newStudentData, setNewStudentData] = useState({});
  const [newParentsData, setNewParentsData] = useState({});

  const [studentPhoto, setStudentPhoto] = useState("");

  const [formState, setFormState] = useState("normal");

  // Getting the user's groups data before rendering the page
  useEffect(() => {
    (async function () {
      let groupData = await getGroupData(userType, ID, groupID);
      setGroupData(groupData);
      setLoaded(true);
    })();
  }, [ID, groupID]);

  useEffect(() => {
    if (progress === 20 && verificationStatus !== "success") {
      setVerificationStatus("awaiting auth code submit");
      setShowSpinner(false);
    }
    setError(null);
  }, [progress]);

  useEffect(() => {
    if (error) setShowSpinner(false);
  }, [error]);

  return (
    <>
      <div className="UIContainer">
        {loaded ? (
          <div className="WelcomeContainer">
            {groupData ? (
              <>
                <div className="progressbarContainer">
                  <ProgressBar now={progress} />
                  {progress !== 0 &&
                  formState !== "sending" &&
                  formState !== "sent" ? (
                    <div className="backButtonContainer">
                      <Button
                        variant="secondary"
                        size="sm"
                        className="newtext newsmallerText backButtonStyle"
                        onClick={() => {
                          setProgress((prevProgress) => prevProgress - 20);
                          setFormState("normal");
                        }}
                      >
                        חזור
                      </Button>
                    </div>
                  ) : null}
                </div>
                {progress === 0 ? (
                  <WelcomePhase
                    setProgress={setProgress}
                    groupData={groupData}
                  />
                ) : null}
                {progress === 20 ? (
                  <div className="contentContainer">
                    {verificationStatus !== "success" && (
                      <h1 className="newtext">הזן קוד מאמן</h1>
                    )}
                    <div id="recaptcha-container"></div>
                    <Form>
                      {verificationStatus === "awaiting auth code submit" ? (
                        <Form.Group className="phoneVerifyContainer">
                          <Form.Control
                            className="newtext newblackText newsmallerText formField weightField"
                            type="newtext"
                            value={authCode}
                            placeholder="קוד מאמן"
                            id="verificationPhoneNumber"
                            onChange={(text) => {
                              setError(false);
                              setAuthCode(text.target.value);
                            }}
                            onBlur={() => {}}
                            isInvalid={false}
                          />
                          {showSpinner ? (
                            <div className="spinnerContainer">
                              <Spinner />
                            </div>
                          ) : null}
                          {error ? (
                            <Form.Label className="newtext newsmallerText label">
                              {error}
                            </Form.Label>
                          ) : null}
                          <Button
                            variant="primary"
                            className="newtext newsmallerText"
                            onClick={initCodeAuth}
                          >
                            המשך
                          </Button>
                        </Form.Group>
                      ) : null}
                      {verificationStatus === "success" ? (
                        <>
                          <Form.Group className="phoneVerifyContainer">
                            <h1
                              className="newtext"
                              style={{ direction: "rtl" }}
                            >
                              אימתנו אותך!
                            </h1>
                            <Button
                              variant="primary"
                              className="newtext newsmallerText"
                              onClick={() => {
                                setProgress(40);
                              }}
                            >
                              המשך
                            </Button>
                          </Form.Group>
                        </>
                      ) : null}
                    </Form>
                  </div>
                ) : null}
                {progress === 40 ? (
                  <StudentDetailsPhase
                    setProgress={setProgress}
                    newStudentData={newStudentData}
                    setNewStudentData={setNewStudentData}
                    studentPhoto={studentPhoto}
                    setStudentPhoto={setStudentPhoto}
                    coachType={coachType}
                    groupData={groupData}
                  />
                ) : null}
                {progress === 60 ? (
                  <ParentsDetailsPhase
                    newStudentData={newStudentData}
                    setProgress={setProgress}
                    newParentsData={newParentsData}
                    setNewParentsData={setNewParentsData}
                  />
                ) : null}
                {progress === 80 ? (
                  <DetailsApprovePhase
                    formState={formState}
                    setFormState={setFormState}
                    newStudentData={newStudentData}
                    newParentsData={newParentsData}
                    setProgress={setProgress}
                    ID={ID}
                    userType={userType}
                    coachType={coachType}
                    groupID={groupID}
                    studentPhoto={studentPhoto}
                    groupData={groupData}
                  />
                ) : null}
                {progress === 100 ? (
                  <div className="proccessFinishedContainer">
                    <>
                      <p className="newtext biggerText">!החניך נרשם בהצלחה</p>
                      {!groupData.paymentUrl ? (
                        <p className="newtext newsmallerText">
                          תהליך הרישום הסתיים, ניתן לסגור את החלון
                        </p>
                      ) : (
                        <>
                          <Button
                            variant="primary"
                            className="newtext newsmallerText"
                            onClick={() => {
                              window.open(
                                groupData.paymentUrl.startsWith("https://")
                                  ? groupData.paymentUrl
                                  : `https://${groupData.paymentUrl}`,
                                "_blank"
                              );
                            }}
                          >
                            למעבר לדף תשלום
                          </Button>
                        </>
                      )}
                    </>
                  </div>
                ) : null}
              </>
            ) : (
              <div className="notfoundContainer">
                <p className="newtext">קבוצה לא נמצאה</p>
              </div>
            )}
          </div>
        ) : (
          <div className="spinnerContainer">
            <Spinner />
          </div>
        )}
      </div>
    </>
  );
};

export default NewStudentForm;
