import React, { useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { FaPlus } from "react-icons/fa";
import "./EventCard.css";
import { convertToDate, convertToTime } from "../../backend/timeFunctions";

const EventCard = ({ event, onEdit, onDelete }) => {
  const [expanded, setExpanded] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleDelete = () => {
    setShowConfirmation(true);
  };

  const confirmDelete = () => {
    onDelete(event);
    setShowConfirmation(false);
  };

  const cancelDelete = () => {
    setShowConfirmation(false);
  };

  const toggleExpanded = () => {
    setExpanded((expanded) => !expanded);
  };

  return (
    <div className="event-card">
      <div className="event-header">
        <h4>{event.name}</h4>
        <Button
          variant="link"
          className="expand-button"
          onClick={toggleExpanded}
        >
          <FaPlus />
        </Button>
      </div>
      {expanded && (
        <div className="event-details">
          <p>
            <strong>מיקום:</strong> {event.location}
          </p>
          <p>
            <strong>תאריך:</strong>{" "}
            {event.date
              ? convertToDate(event.date.seconds, event.date.nanoseconds)
              : "לא נקבע"}
          </p>
          <p>
            <strong>זמן התחלה:</strong>{" "}
            {event.date
              ? convertToTime(event.date.seconds, event.date.nanoseconds)
              : "לא נקבע"}
          </p>
          <p>
            <strong>זמן סיום:</strong>{" "}
            {event.endDate
              ? convertToTime(event.endDate.seconds, event.endDate.nanoseconds)
              : "לא נקבע"}
          </p>
          <p>
            <strong>תיאור:</strong> {event.details}
          </p>
          <div className="footerContainer">
            <Button variant="warning" onClick={() => onEdit(event)}>
              עריכה
            </Button>
            <Button variant="danger" onClick={handleDelete}>
              מחיקה
            </Button>
          </div>

          <Modal show={showConfirmation} onHide={cancelDelete}>
            <Modal.Header className="hebrewHeader">
              <Modal.Title className="hebrewText">
                אישור מחיקת אירוע
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="hebrewText">
                האם אתה בטוח שברצונך למחוק את האירוע "{event.name}"?
                <strong>שים לב, פעולה זו היא בלתי הפיכה!</strong>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={confirmDelete}>
                הבנתי, מחק בכל זאת
              </Button>
              <Button variant="secondary" onClick={cancelDelete}>
                ביטול
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      )}
    </div>
  );
};

export default EventCard;
