import React from "react";
import { useState } from "react";
import "../ManagerDashboard.css";
import HelpBubble from "../Robuts Components/HelpBubble";
import DataTable from "../Robuts Components/DataTable";
import { Button, Modal, Form } from "react-bootstrap";
import {
  writeNewGroup,
  deleteGroup,
  updateExistingGroup,
} from "../../../backend/firebaseFunctions";

function GroupsPane({
  groups,
  activateAlert,
  onGroupUpdate,
  onGroupAdd,
  onGroupDelete,
  userID,
}) {
  // Modal states consolidated into a single object
  const [modalState, setModalState] = useState({
    isOpen: false,
    type: null, // 'details', 'form', 'delete'
    data: null,
  });

  // Form states consolidated into a single object
  const [formData, setFormData] = useState({
    name: "",
    dojo: "",
    description: "",
    trainingSlots: [],
    selectedDays: [],
    id: "",
    isEdited: false,
  });

  const practiceDays = ["א", "ב", "ג", "ד", "ה", "ו", "ש"];

  // Single modal handler function
  const handleModal = (type, data = null) => {
    setModalState({ isOpen: true, type, data });
  };

  // Close modal and reset states
  const closeModal = () => {
    setModalState({ isOpen: false, type: null, data: null });
    if (modalState.type === "form") {
      resetForm();
    }
  };

  // Reset form data
  const resetForm = () => {
    setFormData({
      name: "",
      dojo: "",
      description: "",
      trainingSlots: [],
      selectedDays: [],
      id: "",
      isEdited: false,
    });
  };

  // Form handlers
  const handleFormChange = (field, value) => {
    setFormData((prev) => ({ ...prev, [field]: value }));
  };

  const toggleDay = (day) => {
    setFormData((prev) => {
      const newSelectedDays = prev.selectedDays.includes(day)
        ? prev.selectedDays.filter((d) => d !== day)
        : [...prev.selectedDays, day];

      const newTrainingSlots = prev.selectedDays.includes(day)
        ? prev.trainingSlots.filter((slot) => slot.day !== day)
        : [...prev.trainingSlots];

      return {
        ...prev,
        selectedDays: newSelectedDays,
        trainingSlots: newTrainingSlots,
      };
    });
  };

  const handleHourChange = (day, type, value) => {
    setFormData((prev) => {
      const updatedSlots = [...prev.trainingSlots];
      const slotIndex = updatedSlots.findIndex((slot) => slot.day === day);

      if (slotIndex !== -1) {
        const [start, end] = updatedSlots[slotIndex].range.split("-");
        updatedSlots[slotIndex].range = `${type === "start" ? value : start}-${
          type === "end" ? value : end
        }`;
      } else {
        updatedSlots.push({
          day,
          range: type === "start" ? `${value}-00:00` : `00:00-${value}`,
        });
      }

      return { ...prev, trainingSlots: updatedSlots };
    });
  };

  // CRUD operations
  const handleGroupFormSubmit = async (event) => {
    event.preventDefault();
    const groupData = {
      name: formData.name,
      dojo: formData.dojo,
      trainingSlots: formData.trainingSlots,
      description: formData.description,
      id: formData.id,
    };

    try {
      if (formData.isEdited) {
        const updatedGroup = await updateExistingGroup(userID, groupData);
        onGroupUpdate(updatedGroup);
        activateAlert("success", "אליפות!", "הקבוצה עודכנה בהצלחה");
      } else {
        const newGroup = await writeNewGroup(userID, groupData);
        onGroupAdd(newGroup);
        activateAlert("success", "אליפות!", "הקבוצה נוצרה בהצלחה");
      }
      closeModal();
      resetForm();
    } catch (error) {
      console.error("Failed to save group:", error);
      activateAlert(
        "error",
        "אופס",
        `התרחשה תקלה ב${formData.isEdited ? "עריכת" : "יצירת"} הקבוצה`
      );
    }
  };

  const handleGroupRowClick = (group) => {
    handleModal("details", group);
  };

  const handleEditGroup = (group) => {
    setFormData({
      name: group.name,
      dojo: group.dojo,
      description: group.description,
      trainingSlots: group.trainingSlots,
      selectedDays: [...new Set(group.trainingSlots.map((slot) => slot.day))],
      id: group.id,
      isEdited: true,
    });
    closeModal();
    handleModal("form");
  };

  const handleDeleteGroup = (group) => {
    handleModal("delete", group);
  };

  const handleGroupDelete = async () => {
    try {
      await deleteGroup(userID, modalState.data.id);
      onGroupDelete(modalState.data.id);
      activateAlert("success", "אליפות!", "הקבוצה נמחקה בהצלחה");
    } catch (error) {
      console.error("Failed to delete group:", error);
      activateAlert("error", "אופס", "לא הצלחנו למחוק את הקבוצה");
    }
    closeModal();
  };

  // Helper functions
  const getTimeByType = (day, type) => {
    const slot = formData.trainingSlots.find((slot) => slot.day === day);
    if (!slot) return null;
    const [start, end] = slot.range.split("-");
    return type === "start" ? start : type === "end" ? end : null;
  };

  // Render modals based on type
  const renderModal = () => {
    switch (modalState.type) {
      case "details":
        return (
          <Modal show={modalState.isOpen} onHide={closeModal}>
            <Modal.Header className="eventFormHeader">
              <Modal.Title className="ml-auto">
                {`קבוצת ${modalState.data.name} בסניף ${modalState.data.dojo}`}
              </Modal.Title>
              <Button
                variant="light"
                className="close-button"
                onClick={closeModal}
              >
                &times;
              </Button>
            </Modal.Header>
            <Modal.Body className="hebrewText">
              <p>
                <strong>שם הקבוצה:</strong> {modalState.data.name}
              </p>
              <p>
                <strong>שם הסניף:</strong> {modalState.data.dojo}
              </p>
              <p>
                <strong>תיאור הקבוצה:</strong> {modalState.data.description}
              </p>
              <p>
                <strong>ימי אימון:</strong>{" "}
                {modalState.data.trainingSlots
                  .map((slot) => `יום ${slot.day}' בשעות ${slot.range}`)
                  .join("\n")}
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button
                variant="danger"
                onClick={() => handleDeleteGroup(modalState.data)}
              >
                מחיקה
              </Button>
              <Button
                variant="warning"
                onClick={() => handleEditGroup(modalState.data)}
              >
                עריכה
              </Button>
            </Modal.Footer>
          </Modal>
        );

      case "delete":
        return (
          <Modal show={modalState.isOpen} onHide={closeModal}>
            <Modal.Header className="hebrewHeader">
              <Modal.Title className="hebrewText">
                אישור מחיקת קבוצה
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <p className="hebrewText">
                האם אתה בטוח שברצונך למחוק את קבוצת {modalState.data.name} מסניף{" "}
                {modalState.data.dojo}?
                <strong>שים לב, פעולה זו היא בלתי הפיכה!</strong>
              </p>
            </Modal.Body>
            <Modal.Footer>
              <Button variant="danger" onClick={handleGroupDelete}>
                הבנתי, מחק בכל זאת
              </Button>
              <Button variant="secondary" onClick={closeModal}>
                ביטול
              </Button>
            </Modal.Footer>
          </Modal>
        );

      case "form":
        return (
          <Modal
            show={modalState.isOpen}
            onHide={closeModal}
            className="groupFormModal"
          >
            <Modal.Header className="groupFormHeader hebrewHeader">
              <Modal.Title className="ml-auto">
                {formData.isEdited ? "ערוך קבוצה" : "הוסף קבוצה חדשה"}
              </Modal.Title>
              <Button
                variant="light"
                className="close-button"
                onClick={closeModal}
              >
                &times;
              </Button>
            </Modal.Header>
            <Modal.Body>
              <Form onSubmit={handleGroupFormSubmit}>
                <Form.Group
                  className="groupFormFieldContainer"
                  controlId="groupName"
                >
                  <Form.Label className="hebrewText">שם הקבוצה</Form.Label>
                  <Form.Control
                    type="text"
                    className="groupFormInput"
                    dir="rtl"
                    value={formData.name}
                    onChange={(e) => handleFormChange("name", e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="groupFormFieldContainer"
                  controlId="groupClubName"
                >
                  <Form.Label>שם הסניף</Form.Label>
                  <Form.Control
                    type="text"
                    className="groupFormInput"
                    dir="rtl"
                    value={formData.dojo}
                    onChange={(e) => handleFormChange("dojo", e.target.value)}
                  />
                </Form.Group>
                <Form.Group
                  className="groupFormFieldContainer"
                  controlId="groupDescription"
                >
                  <Form.Label>תיאור הקבוצה</Form.Label>
                  <Form.Control
                    type="text"
                    className="groupFormInput"
                    dir="rtl"
                    value={formData.description}
                    onChange={(e) =>
                      handleFormChange("description", e.target.value)
                    }
                  />
                </Form.Group>
                <Form.Group
                  className="groupFormFieldContainer"
                  controlId="practiceDays"
                >
                  <Form.Label>ימי אימון</Form.Label>
                  <div className="toggleDaysContainer">
                    {practiceDays.map((day) => (
                      <Button
                        key={day}
                        variant={
                          formData.selectedDays.includes(day)
                            ? "primary"
                            : "outline-primary"
                        }
                        className={`toggleDayButton ${
                          formData.selectedDays.includes(day) ? "active" : ""
                        }`}
                        onClick={() => toggleDay(day)}
                      >
                        {day}
                      </Button>
                    ))}
                  </div>
                </Form.Group>
                {formData.selectedDays.map((day) => (
                  <div key={day} className="timeRangeContainer">
                    <Form.Group
                      className="groupFormFieldContainer"
                      controlId={`startHour-${day}`}
                    >
                      <Form.Label>שעת התחלה - {day}</Form.Label>
                      <Form.Control
                        type="time"
                        className="groupFormInput"
                        dir="rtl"
                        defaultValue={getTimeByType(day, "start")}
                        onChange={(e) =>
                          handleHourChange(day, "start", e.target.value)
                        }
                      />
                    </Form.Group>
                    <Form.Group
                      className="groupFormFieldContainer"
                      controlId={`endHour-${day}`}
                    >
                      <Form.Label>שעת סיום - {day}</Form.Label>
                      <Form.Control
                        type="time"
                        className="groupFormInput"
                        onChange={(e) =>
                          handleHourChange(day, "end", e.target.value)
                        }
                        defaultValue={getTimeByType(day, "end")}
                      />
                    </Form.Group>
                  </div>
                ))}
                <div className="buttonsContainer">
                  <Button variant="secondary" onClick={closeModal}>
                    ביטול
                  </Button>
                  <Button variant="primary" type="submit">
                    שמירה
                  </Button>
                </div>
              </Form>
            </Modal.Body>
          </Modal>
        );

      default:
        return null;
    }
  };

  return (
    <div>
      <div>
        <div className="helpHeader">
          <h2>קבוצות</h2>
          <HelpBubble
            text={`במסך הקבוצות תוכל/י לצפות בכלל הקבוצות בארגון, לערוך אותן וכמובן למחוק במידת הצורך! \n `}
          />
        </div>
      </div>

      <Button variant="info" onClick={() => handleModal("form")}>
        <strong>הוסף קבוצה חדשה</strong>
      </Button>

      <p style={{ marginTop: "2vh" }}>לחץ על קבוצה להצגת פירוט</p>
      <div className="groupsContainer">
        <DataTable
          data={groups}
          type={"groups"}
          columnMapping={{
            trainingSlots: "ימי ושעות אימון",
            description: "תיאור",
            name: "שם הקבוצה",
            dojo: "שם הסניף",
          }}
          ignoredAttributes={["endTime", "startTime", "id", "trainingDays"]}
          onRowClick={handleGroupRowClick}
        />
      </div>

      {renderModal()}
    </div>
  );
}

export default GroupsPane;
