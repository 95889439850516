import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  Form,
  Button,
  Card,
  Alert,
  Image,
} from "react-bootstrap";
import companyLogo from "../../images/SimplyCoachFullLogo.png";
import { managerSignIn, managerSignUp } from "../../backend/firebaseFunctions";
import { useNavigate } from "react-router-dom";

const WelcomePage = () => {
  let navigate = useNavigate();

  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [signupEmail, setSignupEmail] = useState("");
  const [signupPassword, setSignupPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [unionName, setUnionName] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [showLogin, setShowLogin] = useState(true);
  const [loginError, setLoginError] = useState("");
  const [signupError, setSignupError] = useState("");
  const [authenticating, setAuthenticating] = useState(false);
  const [signupSuccess, setSignupSuccess] = useState(false);
  const [loginSuccess, setLoginSuccess] = useState(false);

  const translateAuthError = (error) => {
    const errorCode = error.code;
    switch (errorCode) {
      case "auth/user-not-found":
        return "משתמש לא קיים במערכת";
      case "auth/wrong-password":
        return "סיסמה שגויה";
      case "auth/invalid-email":
        return "כתובת אימייל לא תקינה";
      case "auth/email-already-in-use":
        return "כתובת אימייל כבר בשימוש";
      case "auth/weak-password":
        return "הסיסמה חלשה מדי. נא להזין סיסמה חזקה יותר";
      case "auth/network-request-failed":
        return "בעיית תקשורת. נא לבדוק את חיבור האינטרנט";
      case "auth/too-many-requests":
        return "יותר מדי ניסיונות התחברות. נא לנסות שוב מאוחר יותר";
      case "auth/operation-not-allowed":
        return "הפעולה לא מותרת";
      default:
        return "אירעה שגיאה. נא לנסות שוב";
    }
  };

  const handleLoginSubmit = async (e) => {
    e.preventDefault();
    if (!loginEmail || !loginPassword) {
      setLoginError("נא להזין אימייל וסיסמה");
    } else {
      setLoginError("");
      setAuthenticating(true);
      try {
        const user = await managerSignIn(loginEmail, loginPassword);
        setLoginSuccess(true);
        navigate(`dashboard/${user.uid}`);
      } catch (error) {
        setLoginError(translateAuthError(error));
        setLoginSuccess(false);
      } finally {
        setAuthenticating(false);
      }
    }
  };

  const handleSignupSubmit = async (e) => {
    e.preventDefault();
    if (
      !firstName ||
      !lastName ||
      !signupEmail ||
      !signupPassword ||
      !unionName ||
      !phoneNumber
    ) {
      setSignupError("נא למלא את כל השדות הנדרשים");
    } else if (!validateEmail(signupEmail)) {
      setSignupError("נא להזין כתובת אימייל תקינה");
    } else if (!validatePhoneNumber(phoneNumber)) {
      setSignupError("נא להזין מספר טלפון ישראלי תקין");
    } else {
      setSignupError("");
      setAuthenticating(true);
      try {
        const user = await managerSignUp(
          signupEmail,
          signupPassword,
          firstName,
          lastName,
          unionName,
          phoneNumber
        );
        setSignupSuccess(true);
        navigate(`dashboard/${user.uid}`);
      } catch (error) {
        setSignupError(translateAuthError(error));
        setSignupSuccess(false);
      } finally {
        setAuthenticating(false);
      }
    }
  };

  const handleSwitchForm = () => {
    setShowLogin(!showLogin);
    setLoginError("");
    setSignupError("");
    setSignupSuccess(false);
    setLoginSuccess(false);
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex =
      /^(0[23489]|050|052|053|054|055|056|057|058|059)[\d]{7}$/;
    return phoneRegex.test(phoneNumber);
  };

  return (
    <Container className="py-5" dir="rtl">
      <Row className="justify-content-center">
        <Col xs={12} sm={8} md={6}>
          <Card className="shadow" style={{ marginBottom: "2rem" }}>
            <Card.Body className="text-center">
              <Image
                src={companyLogo}
                alt="Company Logo"
                className="mb-4"
                style={{ width: "200px", height: "auto" }}
              />
              <h2 className="font-weight-bold mb-4">
                ברוכים הבאים לדשבורד הניהול
              </h2>

              {showLogin ? (
                <Form>
                  <Form.Group
                    controlId="loginEmail"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="email"
                      placeholder="הזן דואר אלקטרוני"
                      value={loginEmail}
                      onChange={(e) => setLoginEmail(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="loginPassword"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="password"
                      placeholder="הזן סיסמה"
                      value={loginPassword}
                      onChange={(e) => setLoginPassword(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Button
                    variant="primary"
                    type="submit"
                    onClick={handleLoginSubmit}
                    className="btn-block"
                  >
                    התחברות
                  </Button>

                  {/* <p className="mt-3">
                    אין לך חשבון?
                    <Button variant="link" onClick={handleSwitchForm}>
                      הירשם
                    </Button>
                  </p> */}
                </Form>
              ) : (
                <Form>
                  <Form.Group
                    controlId="signupFirstName"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="שם פרטי"
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="signupLastName"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="שם משפחה"
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="signupEmail"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="email"
                      placeholder="הזן דואר אלקטרוני"
                      value={signupEmail}
                      onChange={(e) => setSignupEmail(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="signupPassword"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="password"
                      placeholder="הזן סיסמה"
                      value={signupPassword}
                      onChange={(e) => setSignupPassword(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="signupUnionName"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="שם האיגוד"
                      value={unionName}
                      onChange={(e) => setUnionName(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="signupPhoneNumber"
                    style={{ marginBottom: "1rem" }}
                  >
                    <Form.Control
                      type="text"
                      placeholder="מספר טלפון"
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      dir="rtl"
                    />
                  </Form.Group>

                  <Button
                    variant="success"
                    type="submit"
                    onClick={handleSignupSubmit}
                    className="btn-block"
                  >
                    הרשמה
                  </Button>

                  <p className="mt-3">
                    כבר יש לך חשבון?{" "}
                    <Button variant="link" onClick={handleSwitchForm}>
                      התחבר
                    </Button>
                  </p>
                </Form>
              )}

              {authenticating && <p>מתבצע אימות...</p>}
              {loginError && (
                <Alert variant="danger" style={{ marginTop: "1rem" }}>
                  {loginError}
                </Alert>
              )}
              {signupError && <Alert variant="danger">{signupError}</Alert>}
              {signupSuccess && <Alert variant="success">נרשמת בהצלחה</Alert>}
              {loginSuccess && <Alert variant="success">התחברת בהצלחה</Alert>}
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default WelcomePage;
