import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Container, Nav, Tab, Image, Button } from "react-bootstrap";
import {
  getPresenceLogs,
  getUserData,
  getUserEvents,
  getUserGroups,
  getUserStudents,
  managerLogout,
} from "../../backend/firebaseFunctions";
import "./ManagerDashboard.css";
import companyLogo from "../../images/SimplyCoachFullLogo.png";

import { MdEvent } from "react-icons/md";
import { RiTeamFill } from "react-icons/ri";
import { IoPeopleCircleSharp } from "react-icons/io5";
import { TbReportAnalytics } from "react-icons/tb";
import { IoMdHome } from "react-icons/io";
import { MdOutlineLogin } from "react-icons/md";
import { VscSettings } from "react-icons/vsc";
import { FaFlag } from "react-icons/fa";

import { useNavigate } from "react-router-dom";

import CustomAlert from "./Robuts Components/CustomAlert";
import EventsPane from "./Custom Panes/EventsPane";
import GroupsPane from "./Custom Panes/GroupsPane";
import StudentsPane from "./Custom Panes/StudentsPane";
import NewStudentsPane from "./Custom Panes/NewStudentsPane";
import UpdatesModal from "./Robuts Components/UpdatesModal";
import ReportsPane from "./Custom Panes/ReportsPane";
import HomePane from "./Custom Panes/HomePane";

const Dashboard = () => {
  const { userID } = useParams();
  const [userData, setUserData] = useState(null);
  const [initalDataRecieved, setInitialDataRecieved] = useState(false);
  const [showLogoutModal, setShowLogoutModal] = useState(false);
  const [showUpdatesModal, setShowUpdatesModal] = useState(false);

  let navigate = useNavigate();

  const handleLogout = async () => {
    try {
      await managerLogout();
      navigate(`../manager/auth`);
      setShowLogoutModal(false);
    } catch (err) {
      return err;
    }
  };

  // Current Tab/Pane State
  const [currentTab, setCurrentTab] = useState("home");

  // Data Storage States
  const [events, setEvents] = useState([]);
  const [groups, setGroups] = useState([]);
  const [presenceLogs, setPresenceLogs] = useState([]);
  const [students, setStudents] = useState([]);

  // This functions receives a groupID and returns the group data, if exists in the user's groups
  const getGroupByID = (groupID) => {
    return groups?.find((group) => group.id === groupID) || null;
  };

  // Manage Alert State
  const [alert, setAlert] = useState(null);

  // This function handles the alert component and activates it
  const activateAlert = (type, title, message) => {
    setAlert({
      type: type,
      title: title,
      message: message,
    });
    // Hide Alert after 5 seconds
    setTimeout(() => setAlert(null), 5000);
  };

  // Getting the initial data from the db
  useEffect(() => {
    (async function fetchData() {
      const userData = await getUserData(userID);
      const eventsData = await getUserEvents(userID);
      const groupsData = await getUserGroups(userID);
      const studentsData = await getUserStudents(userID);
      const presenceLogsData = await getPresenceLogs(
        userID,
        groupsData.map((group) => group.id)
      );

      setEvents(eventsData);
      setGroups(groupsData);
      setPresenceLogs(presenceLogsData);
      setStudents(studentsData);
      setUserData(userData);
      setInitialDataRecieved(true);
    })();
  }, [userID]);

  // Event handlers for optimistic updates
  const handleEventUpdate = (updatedEvent) => {
    setEvents((prevEvents) =>
      prevEvents.map((event) =>
        event.id === updatedEvent.id ? updatedEvent : event
      )
    );
  };

  const handleEventAdd = (newEvent) => {
    setEvents((prevEvents) => [...prevEvents, newEvent]);
  };

  const handleEventDelete = (eventId) => {
    setEvents((prevEvents) =>
      prevEvents.filter((event) => event.id !== eventId)
    );
  };

  // Group handlers for optimistic updates
  const handleGroupUpdate = (updatedGroup) => {
    setGroups((prevGroups) =>
      prevGroups.map((group) =>
        group.id === updatedGroup.id ? updatedGroup : group
      )
    );
  };

  const handleGroupAdd = (newGroup) => {
    setGroups((prevGroups) => [...prevGroups, newGroup]);
  };

  const handleGroupDelete = (groupId) => {
    setGroups((prevGroups) =>
      prevGroups.filter((group) => group.id !== groupId)
    );
  };

  // Student handlers for optimistic updates
  const handleStudentUpdate = (updatedStudent) => {
    setStudents((prevStudents) =>
      prevStudents.map((student) =>
        student.id === updatedStudent.id ? updatedStudent : student
      )
    );
  };

  const handleStudentAdd = (newStudent) => {
    setStudents((prevStudents) => [...prevStudents, newStudent]);
  };

  const handleStudentDelete = (studentId) => {
    setStudents((prevStudents) =>
      prevStudents.filter((student) => student.id !== studentId)
    );
  };

  return (
    <div className="page-container">
      <Container className="dashboard-container">
        {userData && (
          <Tab.Container
            defaultActiveKey="groups"
            activeKey={currentTab}
            className="tabContainer"
          >
            <Tab.Content className="dashboard-content">
              <Tab.Pane eventKey="home" className="homepane">
                <HomePane
                  userData={userData}
                  groups={groups}
                  students={students}
                  presenceLogs={presenceLogs}
                  activateAlert={activateAlert}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="events" className="pane">
                <EventsPane
                  userID={userID}
                  events={events}
                  activateAlert={activateAlert}
                  onEventUpdate={handleEventUpdate}
                  onEventAdd={handleEventAdd}
                  onEventDelete={handleEventDelete}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="groups" className="pane">
                <GroupsPane
                  userID={userID}
                  groups={groups}
                  activateAlert={activateAlert}
                  onGroupUpdate={handleGroupUpdate}
                  onGroupAdd={handleGroupAdd}
                  onGroupDelete={handleGroupDelete}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="students" className="pane">
                <StudentsPane
                  students={students}
                  groups={groups}
                  getGroupByID={getGroupByID}
                  setCurrentTab={setCurrentTab}
                  onStudentUpdate={handleStudentUpdate}
                  onStudentAdd={handleStudentAdd}
                  onStudentDelete={handleStudentDelete}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="new_students" className="pane">
                <NewStudentsPane
                  userID={userID}
                  students={students}
                  groups={groups}
                  onStudentAdd={handleStudentAdd}
                  activateAlert={activateAlert}
                  setCurrentTab={setCurrentTab}
                />
              </Tab.Pane>
              <Tab.Pane eventKey="reports" className="pane">
                <ReportsPane
                  groups={groups}
                  students={students}
                  presenceLogs={presenceLogs}
                />
              </Tab.Pane>
            </Tab.Content>
            <div className="dashboard-nav-pane">
              <Nav
                variant="pills"
                className="nav-container"
                activeKey={currentTab}
                onSelect={(k) => {
                  setCurrentTab(k);
                }}
              >
                <Image
                  src={companyLogo}
                  alt="Company Logo"
                  style={{ width: "150px", height: "auto" }}
                />
                <Button
                  variant="info"
                  onClick={() => {
                    // localStorage.clear();
                    setShowUpdatesModal(true);
                  }}
                  size="sm"
                  style={{ margin: "1vh", width: "20vh" }}
                >
                  <strong>עדכונים</strong>
                </Button>
                <div
                  style={{
                    height: "2px",
                    borderBottom: "1px solid grey",
                    width: "100%",
                    opacity: "25%",
                    marginTop: "2vh",
                  }}
                ></div>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="home" className="nav-link">
                    <IoMdHome className="icon" size={30} />
                    בית
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="events" className="nav-link">
                    <MdEvent className="icon" size={30} />
                    אירועים
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="groups" className="nav-link">
                    <RiTeamFill className="icon" size={30} />
                    קבוצות
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="students" className="nav-link">
                    <IoPeopleCircleSharp className="icon" size={30} />
                    חניכים
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="reports" className="nav-link">
                    <TbReportAnalytics className="icon" size={30} />
                    דוחות מידע
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="training-plan" className="nav-link">
                    <FaFlag className="icon" size={30} />
                    תוכנית האימונים
                  </Nav.Link>
                </Nav.Item>
                <div
                  style={{
                    height: "2px",
                    borderBottom: "1px solid grey",
                    width: "100%",
                    opacity: "25%",
                    marginTop: "2vh",
                    marginTop: "5vh",
                  }}
                ></div>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="settings" className="nav-link">
                    <VscSettings className="icon" size={30} />
                    הגדרות
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item className="nav-item">
                  <Nav.Link eventKey="log out" className="logout-nav-link">
                    <MdOutlineLogin className="icon" size={30} />
                    התנתק מהחשבון
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
          </Tab.Container>
        )}
      </Container>
      {alert && (
        <CustomAlert
          type={alert.type}
          title={alert.title}
          message={alert.message}
        />
      )}
      <UpdatesModal
        showUpdatesModal={showUpdatesModal}
        setShowUpdatesModal={setShowUpdatesModal}
      />
    </div>
  );
};

export default Dashboard;
