import React, { useState } from "react";
import { ToggleButton, ButtonGroup, Button, Table } from "react-bootstrap";
import { jsPDF } from "jspdf";
import autoTable from "jspdf-autotable";

function ReportsPane({ groups, students, presenceLogs }) {
  // States
  const [reportType, setReportType] = useState("1");
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfBlob, setPdfBlob] = useState(null);

  const loadHebrewFonts = async (doc) => {
    // Helper function to convert ArrayBuffer to Base64
    const arrayBufferToBase64 = (buffer) => {
      let binary = "";
      const bytes = new Uint8Array(buffer);
      for (let i = 0; i < bytes.length; i++) {
        binary += String.fromCharCode(bytes[i]);
      }
      return btoa(binary);
    };

    // Load Regular Font
    const regularResponse = await fetch("/DavidLibre-Regular.ttf");
    const regularFontData = await regularResponse.arrayBuffer();
    const regularBase64 = arrayBufferToBase64(regularFontData);
    doc.addFileToVFS("DavidLibre-Regular.ttf", regularBase64);
    doc.addFont("DavidLibre-Regular.ttf", "DavidLibre", "normal");

    // Load Bold Font
    const boldResponse = await fetch("/DavidLibre-Bold.ttf");
    const boldFontData = await boldResponse.arrayBuffer();
    const boldBase64 = arrayBufferToBase64(boldFontData);
    doc.addFileToVFS("DavidLibre-Bold.ttf", boldBase64);
    doc.addFont("DavidLibre-Bold.ttf", "DavidLibre", "bold");
  };

  function generateTableRows(data, attributes) {
    return data.map((item) => {
      return attributes.map((attr) => {
        // This part allows us to insert empty columns in the initial table data generation, in order to fill that later in the code
        if (attr === "") {
          return ""; // Store an empty string for empty attributes
        }

        let value = item[attr];

        // Check if the value is a string and contains only digits
        // The jspdf plugin, for some reason, makes number reveres, thus any number needs to be reveresed in order to be displayed right
        if (typeof value === "string" && /^\d+$/.test(value)) {
          return value.split("").reverse().join(""); // Reverse the number string
        }

        if (typeof value === "number") {
          return String(value).split("").reverse().join(""); // Reverse the number string
        }

        return value;
      });
    });
  }

  // This function adds data to the last column
  function addDataToLastColumn(data, valueFunction) {
    const updatedTable = data.map((row) => {
      return [valueFunction(row), ...row];
    });
    return updatedTable;
  }

  // This function replaces values in a specific column using a provided function
  function replaceValuesInColumn(data, columnIndex, valueFunction) {
    return data.map((row) =>
      row.map((value, index) =>
        index === columnIndex ? valueFunction(value) : value
      )
    );
  }

  // This function is used to check if a presence report of a student was in the last 30 days, for students report
  function isWithinLastMonth(logTime) {
    if (!logTime || typeof logTime.seconds !== "number") {
      return false;
    }

    const timestampMs = logTime.seconds * 1000; // Convert seconds to milliseconds
    const currentTime = Date.now();
    const oneMonthAgo = currentTime - 30 * 24 * 60 * 60 * 1000; // 30 days in milliseconds

    return timestampMs >= oneMonthAgo;
  }

  // This function extracts the hebrew name of the report out of the reports_radio object
  const generateReportTitle = (radio_value) => {
    return reports_radio.find((report) => report.value === radio_value)?.name;
  };

  // This function returns the description of each report
  const generateReportDescription = (radio_value) => {
    return reports_radio.find((report) => report.value === radio_value)
      ?.description;
  };

  // This function calls the corresponding report generation function of the current report type the user wants to render
  const generateCurrentReport = (radio_value) => {
    return reports_radio
      .find((report) => report.value === radio_value)
      ?.generationFunction();
  };

  // Function to generate the groups PDF
  const generateGroupsReport = async () => {
    const doc = new jsPDF();

    // Load and set the Hebrew font
    await loadHebrewFonts(doc);
    doc.setFont("DavidLibre", "bold");

    // Get current date and format it as dd/mm/yyyy hh:mm
    const now = new Date();
    const formattedDate = now.toLocaleString("he-IL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    // Add the date under the title
    doc.setFont("DavidLibre", "normal"); // Regular for the date
    doc.setFontSize(12);
    doc.text(formattedDate, 180, 20, { align: "center" });

    // Set the document direction to RTL (for Hebrew text)
    doc.setR2L(true);

    // Title
    doc.setFont("DavidLibre", "bold");
    doc.setFontSize(18);
    doc.text("דוח קבוצות", 105, 20, { align: "center" });
    doc.setFont("DavidLibre", "normal"); // Regular for the body

    // Table data
    // This is the actual columns we want at the table
    const tableColumn = ["ימי אימון", "מספר חניכים", "תיאור", "סניף", "שם"];

    // We generate the rows, based on the groups data, and we extract only the relevant fields, corresponding order to the columns.
    // Pay attention that we also extract the id and traininSlots fields, although it does not have a column
    // This is used for value calculation later
    let tableRows = generateTableRows(groups, [
      "description",
      "dojo",
      "name",
      "id",
      "trainingSlots",
    ]);

    // We dynamically add the data to the "מספר חניכים" column
    tableRows = addDataToLastColumn(tableRows, (row) => {
      let numOfStudentsInGroup = 0;

      students.forEach((student) => {
        // Add null checks and ensure groupsIDs exists and has elements
        if (student?.groupsIDs?.length > 0 && row?.[3]) {
          const studentGroupId = student.groupsIDs[0]?.trim();
          const rowGroupId = row[3]?.trim();
          if (studentGroupId && rowGroupId && studentGroupId === rowGroupId) {
            numOfStudentsInGroup++;
          }
        }
      });

      return numOfStudentsInGroup;
    });

    // We dynamically add the data to the "ימי אימון" column
    tableRows = addDataToLastColumn(tableRows, (row) => {
      // Add null check for trainingSlots
      if (!row?.[5] || !Array.isArray(row[5])) {
        return "-";
      }

      // Sort training slots by day number for consistent ordering
      const sortedSlots = [...row[5]].sort((a, b) => {
        const dayA = parseInt(a.day) || 0;
        const dayB = parseInt(b.day) || 0;
        return dayA - dayB;
      });

      const daysMap = {
        1: "ראשון",
        2: "שני",
        3: "שלישי",
        4: "רביעי",
        5: "חמישי",
        6: "שישי",
        7: "שבת",
      };

      return (
        sortedSlots
          .map((slot) => {
            if (!slot?.day || !slot?.range) {
              return "";
            }
            const dayName = daysMap[slot.day] || slot.day;
            return `יום ${dayName} ${slot.range}`;
          })
          .filter(Boolean)
          .join("\n") || "-"
      );
    });

    // Stats Text
    doc.setFont("DavidLibre", "normal");
    doc.setFontSize(12);
    doc.text("מספר הקבוצות :", 180, 30, { align: "center" });
    doc.text("מספר החניכים :", 180, 35, { align: "center" });
    doc.setR2L(false);
    doc.text(`${groups.length}`, 160, 30, { align: "center" });
    doc.text(`${students.length}`, 160, 35, { align: "center" });

    // Change again the R2L status
    doc.setR2L(true);
    doc.setFont("DavidLibre", "normal"); // Regular for the body

    // The table
    autoTable(doc, {
      startY: 40,
      head: [tableColumn],
      body: tableRows,
      margin: { left: 20, right: 20 },
      tableWidth: "auto",
      styles: {
        font: "DavidLibre",
        fontSize: 12,
        halign: "right",
        direction: "rtl",
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        lineWidth: 0.001,
      },
      theme: "grid",
      headStyles: {
        fillColor: [232, 232, 232],
        textColor: [0, 0, 0],
        lineColor: [0, 0, 0],
        fontSize: 11,
        cellPadding: 1,
        minCellHeight: 8,
        valign: "middle",
      },
      columnStyles: {
        0: { cellWidth: 40 },
        1: { cellWidth: 25, halign: "right", direction: "ltr" },
        2: { cellWidth: 40 },
        3: { cellWidth: 25 },
        4: { cellWidth: 35 },
      },
      didParseCell: function (data) {
        if (data.section === "head") {
          data.cell.styles.cellPadding = 1;
          data.cell.styles.minCellHeight = 8;
        } else if (data.column.index === 0) {
          data.cell.styles.minCellHeight = 8;
          data.cell.styles.cellPadding = 3;
        }
      },
    });

    // Generate and store PDF
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setPdfBlob(pdfBlob);
    setPdfUrl(pdfUrl);
  };

  // Function to generate the groups PDF
  const generateStudentsReport = async () => {
    const doc = new jsPDF();

    // Load and set the Hebrew font
    await loadHebrewFonts(doc);
    doc.setFont("DavidLibre", "bold");

    // Get current date and format it as dd/mm/yyyy hh:mm
    const now = new Date();
    const formattedDate = now.toLocaleString("he-IL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
      hour: "2-digit",
      minute: "2-digit",
    });

    // Add the date under the title
    doc.setFont("DavidLibre", "normal");
    doc.setFontSize(12);
    doc.text(formattedDate, 180, 20, { align: "center" });

    // Set the document direction to RTL (for Hebrew text)
    doc.setR2L(true);

    // Title
    doc.setFont("DavidLibre", "bold");
    doc.setFontSize(18);
    doc.text("דוח חניכים", 105, 20, { align: "center" });
    doc.setFont("DavidLibre", "normal");

    // Table data
    // This is the actual columns we want at the table
    const tableColumn = [
      "אחוז נוכחות *",
      "מספר טלפון הורה",
      "שם הורה",
      "קבוצות אימון",
      "כתובת",
      "גיל",
      "מין",
      "שם",
    ];

    // We generate the rows
    // Pay attention, we make sure that each column has a corresponding object attribute that gets inserted
    // There are special columns that require further modifying (like אחוז נוכחות and קבוצות אימון), thus we fill in a relevant field to help us compute it later.
    let tableRows = generateTableRows(students, [
      "id",
      "firstParentNumber",
      "firstParentName",
      "groupsIDs",
      "address",
      "age",
      "gender",
      "name",
    ]);

    // This is used to generate group text
    tableRows = replaceValuesInColumn(tableRows, 3, (value) => {
      if (!value?.[0]) return "-";
      const studentGroup = groups.find((group) => group?.id === value[0]);
      if (!studentGroup) return "-";

      return `${studentGroup?.name || "-"}, ${studentGroup?.dojo || "-"}`;
    });

    // This is used to generate stats column text
    tableRows = replaceValuesInColumn(tableRows, 0, (value) => {
      if (!value) return "%0";

      let presencePrecent = 0;
      let totalClassesPresent = 0;
      let totalClassesMissed = 0;
      let totalClasses = 0;

      const student = students.find((student) => student?.id === value);
      if (!student?.classPresenceRecords?.length) return "%0";

      for (let i = 0; i < student.classPresenceRecords.length; i++) {
        const record = student.classPresenceRecords[i];
        if (!record?.classTime) continue;

        if (isWithinLastMonth(record.classTime)) {
          if (record.wasPresent) totalClassesPresent++;
          else totalClassesMissed++;
        }
      }

      totalClasses = totalClassesPresent + totalClassesMissed;
      if (totalClasses === 0) return "%0";
      presencePrecent = Math.round((totalClassesPresent / totalClasses) * 100);

      return `%${String(presencePrecent).split("").reverse().join("")}`;
    });

    // Stats Text
    doc.setFont("DavidLibre", "normal");
    doc.setFontSize(12);
    doc.text("מספר החניכים :", 182, 35, { align: "center" });
    doc.setR2L(false);
    doc.text(`${students.length}`, 160, 35, { align: "center" });

    // Change again the R2L status
    doc.setR2L(true);
    // Add a comment
    doc.setFontSize(10);
    doc.text(
      "הערה: אחוז הנוכחות מחושב על ידי בדיקת נוכחות החניך בכלל האימונים שבוצעו ב-30 הימים האחרונים",
      197,
      45,
      { align: "right" }
    );

    // The table
    doc.setFont("DavidLibre", "normal");
    autoTable(doc, {
      startY: 50,
      head: [tableColumn],
      body: tableRows,
      pageBreak: "auto", // Ensures rows move to the next page properly
      styles: {
        font: "DavidLibre", // Ensure this matches the font you added
        fontSize: 12,
        halign: "right", // Right-align text
        direction: "rtl", // Ensure right-to-left text flow
        textColor: [0, 0, 0], // Set text color to black
        lineColor: [0, 0, 0], // Set grid lines to black
        lineWidth: 0.001, // Adjust thickness of grid lines
      },
      theme: "grid",
      headStyles: {
        fillColor: [232, 232, 232], // Light gray background for header
        textColor: [0, 0, 0], // Ensure header text is black
        lineColor: [0, 0, 0], // Ensure header grid lines are black
      },
      columnStyles: {
        0: {
          halign: "right",
          cellWidth: "auto",
          cellStyles: { direction: "ltr" },
        }, // Ensure phone numbers are LTR
      },
    });

    // Generate and store PDF
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setPdfBlob(pdfBlob);
    setPdfUrl(pdfUrl);
  };

  const generatePresenceReport = async () => {
    console.log("Starting presence report generation");
    console.log("Total presence logs:", presenceLogs.length);
    console.log("Total groups:", groups.length);

    const doc = new jsPDF();

    // Load and set the Hebrew font
    await loadHebrewFonts(doc);
    doc.setFont("DavidLibre", "bold");

    // Get current date and format it as dd/mm/yyyy hh:mm
    const now = new Date();
    const formattedDate = now.toLocaleDateString("he-IL", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const formattedTime = now.toLocaleTimeString("he-IL", {
      hour: "2-digit",
      minute: "2-digit",
    });

    // Set the document direction to RTL (for Hebrew text)
    doc.setR2L(true);

    // Sort groups by dojo
    const sortedGroups = [...groups].sort((a, b) => {
      // First sort by dojo
      const dojoCompare = a.dojo.localeCompare(b.dojo);
      if (dojoCompare !== 0) return dojoCompare;
      // If same dojo, sort by name
      return a.name.localeCompare(b.name);
    });

    // Process each group
    for (let groupIndex = 0; groupIndex < sortedGroups.length; groupIndex++) {
      const group = sortedGroups[groupIndex];
      console.log(
        `Processing group: ${group.name} with ${
          students.filter(
            (student) => student?.groupsIDs?.[0]?.trim() === group?.id?.trim()
          ).length
        } students`
      );

      // Add new page for each group except the first one
      if (groupIndex > 0) {
        doc.addPage();
      }

      // Title
      doc.setFont("DavidLibre", "bold");
      doc.setFontSize(18);
      doc.text(`דוח נוכחות - ${group.name}`, 105, 15, { align: "center" });

      // Dojo subtitle
      doc.setFont("DavidLibre", "normal");
      doc.setFontSize(14);
      doc.text(`סניף: ${group.dojo}`, 105, 25, { align: "center" });

      // Date and Time
      doc.setFontSize(12);
      doc.setR2L(false); // Temporarily disable RTL for date/time
      doc.text(`${formattedDate} ${formattedTime}`, 180, 20, {
        align: "center",
      });
      doc.setR2L(true); // Re-enable RTL for the rest of the content

      // Get all students in this group
      const groupStudents = students.filter(
        (student) => student?.groupsIDs?.[0]?.trim() === group?.id?.trim()
      );
      console.log(
        `Found ${groupStudents.length} students in group ${group.name}`
      );

      // Get all unique dates from presence logs for this group within the last 30 days
      const logsByDate = new Map(); // Map to store logs by date
      const groupLogs = presenceLogs.filter(
        (log) => log?.groupID === group?.id
      );
      console.log(`Found ${groupLogs.length} logs for this group`);

      // Create an array of all valid logs within the last 30 days
      const validLogs = groupLogs
        .filter((log) => {
          if (!log?.logTime?.seconds) return false;
          return isWithinLastMonth(log.logTime);
        })
        .sort(
          (a, b) => (b?.logTime?.seconds || 0) - (a?.logTime?.seconds || 0)
        ); // Sort by most recent first

      console.log(`Found ${validLogs.length} valid logs within last 30 days`);

      // Take only the 16 most recent logs
      const limitedLogs = validLogs.slice(0, 16);
      console.log(`Limited to ${limitedLogs.length} most recent logs`);

      // Group logs by date
      limitedLogs.forEach((log) => {
        if (!log?.logTime?.seconds) return;

        const date = new Date(log.logTime.seconds * 1000);
        // Format date in DD/MM format and reverse the numbers to handle RTL
        const formattedLogDate = date
          .toLocaleDateString("he-IL", {
            day: "2-digit",
            month: "2-digit",
          })
          .split("/")
          .map((part) => part.split("").reverse().join(""))
          .join("/");

        if (!logsByDate.has(formattedLogDate)) {
          logsByDate.set(formattedLogDate, []);
        }
        logsByDate.get(formattedLogDate).push(log);
      });

      const allDates = Array.from(logsByDate.keys());
      console.log(`Found ${allDates.length} unique dates with logs`);

      // If there are no dates in the last 30 days, show a message
      if (allDates.length === 0) {
        doc.setFont("DavidLibre", "normal");
        doc.setFontSize(14);
        doc.text("לא התקיימו אימונים בקבוצה זו ב-30 הימים האחרונים", 105, 40, {
          align: "center",
        });
      } else {
        // Sort dates chronologically
        const sortedDates = allDates.sort((a, b) => {
          const [dayA, monthA] = a
            .split("/")
            .map((part) => part.split("").reverse().join(""));
          const [dayB, monthB] = b
            .split("/")
            .map((part) => part.split("").reverse().join(""));
          return (
            new Date(2024, monthB - 1, dayB) - new Date(2024, monthA - 1, dayA)
          );
        });

        // Prepare table data
        const tableColumns = [...sortedDates, "שם"]; // Changed order to show dates on the left
        const tableRows = groupStudents.map((student) => {
          const row = [];

          // Add presence data for each date
          sortedDates.forEach((date) => {
            const dateLogs = logsByDate.get(date) || [];
            // Sort logs by timestamp to ensure consistent order
            const sortedLogs = [...dateLogs].sort(
              (a, b) => (b?.logTime?.seconds || 0) - (a?.logTime?.seconds || 0)
            );

            // Find all logs for this student on this date
            const studentLogs = sortedLogs.filter((log) =>
              log?.presenceLogDataForFront?.some(
                (data) => data?.studentID === student?.id
              )
            );

            // If any log shows presence, mark as present
            const wasPresent = studentLogs.some(
              (log) =>
                log?.presenceLogDataForFront?.find(
                  (data) => data?.studentID === student?.id
                )?.isPresent
            );

            row.push(wasPresent ? "V" : "-");
          });

          // Add student name at the end (rightmost column)
          row.push(student?.name || "-");
          return row;
        });

        // Add the table
        autoTable(doc, {
          startY: 30,
          head: [tableColumns],
          body: tableRows,
          styles: {
            font: "DavidLibre",
            fontSize: 10,
            halign: "right",
            direction: "rtl",
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0],
            lineWidth: 0.001,
          },
          theme: "grid",
          headStyles: {
            fillColor: [232, 232, 232],
            textColor: [0, 0, 0],
            lineColor: [0, 0, 0],
            direction: "ltr", // Force LTR for date headers
          },
          columnStyles: {
            [tableColumns.length - 1]: { cellWidth: "auto" }, // Style for the name column
          },
        });

        // Add a note about the time period and log limit
        doc.setFontSize(10);
        doc.text(
          "הערה: הדוח מציג את נוכחות החניכים באימונים שבוצעו ב-30 הימים האחרונים )מקסימום 16 אימונים(",
          197,
          doc.lastAutoTable.finalY + 10,
          { align: "right" }
        );
      }
    }

    // Generate and store PDF
    const pdfBlob = doc.output("blob");
    const pdfUrl = URL.createObjectURL(pdfBlob);

    setPdfBlob(pdfBlob);
    setPdfUrl(pdfUrl);
    console.log("PDF generation completed");
  };

  const downloadPdf = () => {
    if (pdfBlob) {
      // Create a link to download the PDF file
      const link = document.createElement("a");
      link.href = URL.createObjectURL(pdfBlob); // Create a download link
      link.download = "report.pdf"; // Set a default filename
      link.click(); // Trigger the download
    }
  };

  const reports_radio = [
    {
      name: `דו"ח נוכחות`,
      value: "3",
      description: `דו"ח הנוכחות מציג את נוכחות החניכים בכל קבוצה, כאשר כל עמוד מייצג קבוצה אחת. העמודות מייצגות את תאריכי האימונים, והתאים מכילים V לנוכחות או - להיעדרות`,
      generationFunction: generatePresenceReport,
    },
    {
      name: `דו"ח חניכים`,
      value: "2",
      description: `דו"ח החניכים מציג את כלל החניכים בארגון והפרטים שלהם, כולל חישוב סטטיסטי של הנוכחות שלהם באימונים האחרונים על בסיס דיווחי הנוכחות שהוזנו`,
      generationFunction: generateStudentsReport,
    },
    {
      name: `דו"ח קבוצות`,
      value: "1",
      description: `דו"ח הקבוצות מציג את כלל הקבוצות בארגון והפרטים שלהן`,
      generationFunction: generateGroupsReport,
    },
  ];

  return (
    <div>
      <ButtonGroup className="mb-2">
        {reports_radio.map((radio, idx) => (
          <ToggleButton
            key={idx}
            id={`radio-${idx}`}
            type="radio"
            variant="light"
            name="radio"
            value={radio.value}
            size="sm"
            checked={reportType === radio.value}
            onChange={(e) => setReportType(e.currentTarget.value)}
            style={{
              borderColor: "grey",
              borderWidth: "1px",
              color: "	#3b3b3b",
            }}
          >
            <strong>{radio.name}</strong>
          </ToggleButton>
        ))}
      </ButtonGroup>
      <div>
        <h3>{generateReportTitle(reportType)}</h3>
        <p style={{ direction: "rtl", marginTop: "1vh", marginBottom: "1vh" }}>
          {generateReportDescription(reportType)}
        </p>
        <Button
          onClick={() => {
            generateCurrentReport(reportType);
          }}
          style={{ direction: "rtl", marginBottom: "2vh" }}
        >
          צור דוח
        </Button>
      </div>
      <iframe
        src={pdfUrl}
        title="Generated PDF"
        width="100%"
        height="400vh"
        style={{ border: "none" }}
      />
      <Button
        onClick={downloadPdf}
        style={{ direction: "rtl", marginTop: "2vh" }}
      >
        הורד דוח בפורמט PDF
      </Button>
    </div>
  );
}

export default ReportsPane;
