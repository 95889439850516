import React, { useState } from "react";
import DataTable from "../Robuts Components/DataTable";
import { Modal, Button } from "react-bootstrap";
import { IoReturnDownForward } from "react-icons/io5";
import {
  acceptNewStudent,
  deleteStudent,
} from "../../../backend/firebaseFunctions";

function NewStudentsPane({
  students,
  groups,
  setCurrentTab,
  userID,
  activateAlert,
  fetchStudentsAgain,
}) {
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [showStudentApproveModal, setShowStudentApproveModal] = useState(false);

  const onNewStudentRowClick = (student) => {
    setSelectedStudent(student);
    setShowStudentApproveModal(true);
  };

  return (
    <div>
      <Button
        variant="secondary"
        onClick={() => {
          setCurrentTab("students");
        }}
        style={{ marginBottom: "2vh" }}
      >
        חזור
        <IoReturnDownForward size={24} style={{ marginLeft: "2vh" }} />
      </Button>
      <p>לחץ על החניך בכדי לאשר או לדחות אותו</p>
      <DataTable
        messageIfEmpty="אין חניכים לאישור"
        data={students}
        type="newstudents"
        columnMapping={{
          address: "כתובת",
          age: "גיל",
          gender: "מין",
          name: "שם החניך",
          groupsIDs: "קבוצת החניך",
        }}
        ignoredAttributes={[
          "id",
          "beltColor",
          "birthday",
          "classPresenceRecords",
          "firstParentEmailAddress",
          "firstParentName",
          "firstParentNumber",
          "imageURI",
          "imageUploadTime",
          "specialFields",
          "status",
          "weight",
          "weightKG",
          "secondParentEmailAddress",
          "secondParentName",
          "secondParentNumber",
          "IDnumber",
          "studentID",
          "studentCreatedTime",
          "studentSource",
          "createdAt",
          "shortsSize",
          "pantsSize",
        ]}
        onRowClick={onNewStudentRowClick}
        metaData={groups}
      />
      {showStudentApproveModal && (
        <Modal
          show={showStudentApproveModal}
          onHide={() => {
            setShowStudentApproveModal(false);
          }}
        >
          <Modal.Header className="hebrewHeader">
            <Modal.Title className="hebrewText">
              אישור חניך - {selectedStudent.name}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <p className="hebrewText">האם תרצה לאשר או לדחות את חניך זה?</p>
            <p className="hebrewText">
              שים לב! דחיית החניך תגרום למחיקת הפרטים שלו מהמערכת
            </p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="danger"
              onClick={async () => {
                try {
                  // Perform delete action
                  const isStudentDeleted = deleteStudent(
                    userID,
                    selectedStudent.id
                  );
                  if (isStudentDeleted) {
                    console.log(`student deleted successfully`);
                    activateAlert("success", "אליפות!", "החניך נמחק מהמערכת");
                    fetchStudentsAgain();
                  }
                } catch {
                  console.log("student deletion failed");
                }
                setShowStudentApproveModal(false);
              }}
            >
              דחה את החניך
            </Button>
            <Button
              variant="success"
              onClick={() => {
                try {
                  const isStudentAccepted = acceptNewStudent(
                    userID,
                    selectedStudent.id
                  );
                  if (isStudentAccepted) {
                    console.log(`student accepted successfully`);
                    activateAlert("success", "אליפות!", "החניך אושר במערכת");
                    fetchStudentsAgain();
                  }
                } catch {
                  console.log("student acception failed");
                }
                setShowStudentApproveModal(false);
              }}
            >
              אשר את החניך
            </Button>
            <Button
              variant="secondary"
              onClick={() => {
                setShowStudentApproveModal(false);
              }}
            >
              ביטול
            </Button>
          </Modal.Footer>
        </Modal>
      )}
    </div>
  );
}

export default NewStudentsPane;
